import React, {useEffect, useState} from 'react';
import s from './Transfers.module.css'
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import MySelectAccounts from "../../components/ui/MySelectAccounts/MySelectAccounts";
import {SendTransferThunk} from "../../store/reducers/ActionCreators";
import {useAccountsForSelect} from "../../hooks/useAccountsForSelect";
import CustomCheckbox from "../../components/ui/customCheckbox/CustomCheckbox";
import {useFormik} from "formik";
import CustomBtnPink from "../../components/ui/customBtnPink/CustomBtnPink";
import classnames from "classnames";
import * as yup from 'yup';

const validationSchema = yup.object({
    amount: yup.number().required('Required field'),
})

const Transfers = () => {

    const dispatch = useAppDispatch()
    const userToken: string = useAppSelector( state => state.userReducer.user.userToken)
    const [checkedTerms, setCheckedTerms] = useState<boolean>(false);
    const [checkedError, setCheckedError] = useState<boolean>(false);
    const [selectedAccountCurrency, setSelectedAccountCurrency] = useState("All");
    const [isMobile, setIsMobile] = useState(false)

    const accountsForSelect = useAccountsForSelect()

    const formikSwift = useFormik({
        initialValues: {
            fromAccount: "",
            amount: undefined,
            reference: "",
            bankName: "",
            bankAddress: "",
            beneficiaryName: "",
            beneficiaryCountry: "",
            beneficiaryCity: "",
            beneficiaryAddress: "",
            swiftCode: "",
            ibanCode: ""
        },
        validationSchema,
        onSubmit: values => {
            if (!checkedTerms) {
                setCheckedError(true)
                return
            }
            dispatch(SendTransferThunk(userToken, values))
        },
    });

    useEffect(() => {
        accountsForSelect.map((account) => {
            if (account.number === formikSwift.values.fromAccount) {
                setSelectedAccountCurrency(account.currency)
            }
        })
    },[formikSwift.values.fromAccount])

    const handleCheckboxChange = () => {
        setCheckedTerms(!checkedTerms)
        setCheckedError(false)
    }

    useEffect(() => {

        if (window.innerWidth < 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }

    }, [])

    return (
        <div className={s.wrapper}>
            <form onSubmit={formikSwift.handleSubmit}>
                <div className={s.title}>
                    Transfer Details
                </div>
                <div className={s.inputs_wrapper_account}>
                    <div className={s.input_block}>
                        <div className={s.input_title}>
                            Account Number
                        </div>
                        <MySelectAccounts item={accountsForSelect}
                                          width={ isMobile ? 255 : 322}
                                          height={isMobile ? 40 : 58}
                                          id="fromAccount"
                                          name="fromAccount"
                                          onAccountSelectHandler={formikSwift.handleChange}
                                          selectedItem={formikSwift.values.fromAccount}
                        />
                    </div>

                    <div className={s.input_block}>
                        <div className={s.input_title}>
                            Currency
                        </div>
                        <div className={s.current_currency}>
                            {selectedAccountCurrency}
                        </div>
                    </div>
                </div>
                <div className={s.input_block}>
                    <div className={s.input_title}>
                        Amount
                    </div>
                    <input value={formikSwift.values.amount}
                           onChange={formikSwift.handleChange}
                           type="text"
                           className={classnames( formikSwift.errors.amount ? s.error_input : "", s.my_input)}
                           id="amount"
                           name="amount"
                    />
                    {formikSwift.errors.amount && <div className={s.error}>numbers only</div>}
                </div>

                <div className={s.title}>
                    Transaction Details
                </div>

                <div className={s.input_block}>
                    <div className={s.input_title}>
                        Bank Name
                    </div>
                    <input
                        value={formikSwift.values.bankName}
                        onChange={formikSwift.handleChange}
                        id="bankName"
                        name="bankName"
                        type="text"
                        className={s.my_input_long}
                    />
                </div>
                <div className={s.input_block}>
                    <div className={s.input_title}>
                        Bank Address
                    </div>
                    <input
                        value={formikSwift.values.bankAddress}
                        onChange={formikSwift.handleChange}
                        id="bankAddress"
                        name="bankAddress"
                        type="text"
                        className={s.my_input_long}
                    />
                </div>
                <div className={s.inputs_wrapper}>
                    <div className={s.input_block}>
                        <div className={s.input_title}>
                            SWIFT Code
                        </div>
                        <input
                            type="text"
                            className={s.my_input}
                            value={formikSwift.values.swiftCode}
                            onChange={formikSwift.handleChange}
                            id="swiftCode"
                            name="swiftCode"
                            required
                        />
                    </div>

                    <div className={s.input_block}>
                        <div className={s.input_title}>
                            IBAN
                        </div>
                        <input
                            value={formikSwift.values.ibanCode}
                            onChange={formikSwift.handleChange}
                            id="ibanCode"
                            name="ibanCode"
                            type="text"
                            required
                            className={s.my_input}
                        />
                    </div>
                </div>
                <div className={s.inputs_wrapper}>
                    <div className={s.input_block}>
                        <div className={s.input_title}>
                            Beneficiary Name
                        </div>
                        <input
                            value={formikSwift.values.beneficiaryName}
                            onChange={formikSwift.handleChange}
                            id="beneficiaryName"
                            name="beneficiaryName"
                            type="text"
                            className={s.my_input_long}
                        />
                    </div>
                </div>
                <div className={s.inputs_wrapper}>
                    <div className={s.input_block}>
                        <div className={s.input_title}>
                            Beneficiary Country
                        </div>
                        <input
                            value={formikSwift.values.beneficiaryCountry}
                            onChange={formikSwift.handleChange}
                            id="beneficiaryCountry"
                            name="beneficiaryCountry"
                            type="text"
                            className={s.my_input}
                        />
                    </div>

                    <div className={s.input_block}>
                        <div className={s.input_title}>
                            Beneficiary City
                        </div>
                        <input
                            value={formikSwift.values.beneficiaryCity}
                            onChange={formikSwift.handleChange}
                            id="beneficiaryCity"
                            name="beneficiaryCity"
                            type="text"
                            className={s.my_input}
                        />
                    </div>
                </div>
                <div className={s.input_block}>
                    <div className={s.input_title}>
                        Beneficiary Address
                    </div>
                    <input
                        value={formikSwift.values.beneficiaryAddress}
                        onChange={formikSwift.handleChange}
                        id="beneficiaryAddress"
                        name="beneficiaryAddress"
                        type="text"
                        className={s.my_input_long}
                    />
                </div>

                <div className={s.title}>
                    Other Details
                </div>

                <div className={s.input_block}>
                    <div className={s.input_title}>
                        Purpose
                    </div>
                    <textarea
                        value={formikSwift.values.reference}
                        onChange={formikSwift.handleChange}
                        id="reference"
                        name="reference"
                        className={s.my_textarea}
                    />
                </div>
                <div className={s.terms_block}>
                    <CustomCheckbox handleChange={handleCheckboxChange}
                                    isChecked={checkedTerms}
                    />

                    <span className={checkedError ? s.checked_error : s.default}>I agree with GBH Coriolis</span>
                    <span className={s.pink}>
                        Terms and Conditions
                    </span>
                </div>
                <div className={s.transfer_button_wrapper}>
                    <div className={s.transfer_button_block}>
                        <CustomBtnPink title={"Transfer"} type={"submit"}/>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Transfers;
