import React, {useEffect, useState} from 'react';
import s from './Header.module.css'
import NavBar from "../navBar/NavBar";
import logo from '../../assets/img/logo.png'
import whatsapp from '../../assets/img/telegramm.png'
import CustomBtnTransparent from "../ui/customBtnTransparent/CustomBtnTransparent";
import CustomBtnPink from "../ui/customBtnPink/CustomBtnPink";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {HOME, LOGIN, OPEN_ACCOUNT} from "../../router/Constants";
import menu from '../../assets/img/mobile/menu.png'
import {userSlice} from "../../store/reducers/UserSlice";
import {useAppDispatch} from "../../hooks/redux";

type PropsType = {
    setIsMobileMenuActive: (boolean: boolean) => void
}

const Header = (props: PropsType) => {

    const navigate = useNavigate()

    const location = useLocation()
    const [isNavShow, setIsNavShow] = useState(false)
    const [isLoginPage, setIsLoginPage] = useState(false)

    useEffect(() => {
        if (location.pathname.includes("open_account")) {
            setIsNavShow(false)
        } else if (location.pathname.includes("log_in")) {
            setIsNavShow(false)
            setIsLoginPage(true)
        } else {
            setIsNavShow(true)
            setIsLoginPage(false)
        }
    }, [location.pathname])
    const {setMessageSentSuccess} = userSlice.actions
    const dispatch = useAppDispatch()

    const redirect = () => {
        navigate(LOGIN)
        dispatch(setMessageSentSuccess(null))
    }

    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <Link to={HOME}>
                    <img src={logo} alt=""/>
                </Link>
                {
                    isNavShow
                        ? <div className={s.buttons_block}>
                            <div className={s.whatsapp_wrapper}>
                                <a href="https://wa.me/+37254640735" target="_blank">
                                    <img style={{marginTop: 3}} src={whatsapp} alt=""/>
                                </a>
                            </div>
                            <div className={s.login_btn_wrapper}>
                                <CustomBtnTransparent
                                    title={"log in"}
                                    isLogin
                                    onClick={() => navigate(LOGIN)}
                                />
                            </div>
                            <div className={s.btn_open_block}>
                                <CustomBtnPink
                                    title={"Open Account"}
                                    onClick={() => navigate(OPEN_ACCOUNT)}
                                />
                            </div>
                        </div>
                        : <div className={s.buttons_block}>
                            {
                                isLoginPage
                                    ? <CustomBtnTransparent
                                        isBack
                                        title={"back"}
                                        onClick={() => navigate(-1)}
                                    />
                                    : <CustomBtnTransparent
                                        title={"log in"}
                                        isLogin
                                        onClick={() => redirect()}
                                    />
                            }
                        </div>
                }
                <div className={s.mobile_flex}>
                    <div className={s.whatsapp_wrapper_mobile}>
                        <a href="https://wa.me/+37254640735" target="_blank">
                            <img style={{marginTop: 3}} src={whatsapp} alt=""/>
                        </a>
                    </div>
                    <div>
                        <img onClick={() => props.setIsMobileMenuActive(true)} src={menu} alt=""/>
                    </div>
                </div>
            </div>

            {
                isNavShow
                    ? <div className={s.navbar_wrapper}>
                        <NavBar/>
                    </div>
                    : <></>
            }
        </div>
    );
};

export default Header;
