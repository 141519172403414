import React, {useEffect} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {getInternationalTransactionDetailsThunk} from "../../../store/reducers/ActionCreators";
import {transactionType} from "../../../store/reducers/UserSlice";
import s from './InternationalTransactionInfo.module.css'
import CustomBtnPink from "../../../components/ui/customBtnPink/CustomBtnPink";
import {STATEMENT} from "../../../router/Constants";

const InternationalTransactionInfo = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useAppDispatch()
    const userToken: string = useAppSelector( state => state.userReducer.user.userToken)
    const transactionDetails: transactionType | null = useAppSelector(state => state.userReducer.internationalTransactionDetails)

    const transactionNumber = location.search.slice(4)

    useEffect(() => {
        dispatch(getInternationalTransactionDetailsThunk(userToken,transactionNumber))
    }, [])

    return (
        <div>
            <div className={s.info_block}>
                <div className={s.block_title}>
                    Transfer Details
                </div>
                <div>
                    <div className={s.row}>
                        <div className={s.detail_title}>
                            Account Number:
                        </div>
                        <div className={s.detail_value}>
                            {transactionDetails?.fromAccount}
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.detail_title}>
                            Currency:
                        </div>
                        <div className={s.detail_value}>
                            {transactionDetails?.currency}
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.detail_title}>
                            Amount:
                        </div>
                        <div className={s.detail_value}>
                            {transactionDetails?.amount}
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.detail_title}>
                            Type:
                        </div>
                        <div className={s.detail_value}>
                            {
                                transactionDetails?.direction === "IN"
                                    ? <span>Deposit</span>
                                    : <span>Withdrawal</span>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.info_block}>
                <div className={s.block_title}>
                    Transaction Details
                </div>
                <div>
                    <div className={s.row}>
                        <div className={s.detail_title}>
                            Bank Name:
                        </div>
                        <div className={s.detail_value}>
                            {transactionDetails?.bankName}
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.detail_title}>
                            Bank Address:
                        </div>
                        <div className={s.detail_value}>
                            {transactionDetails?.bankAddress}
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.detail_title}>
                            SWIFT code:
                        </div>
                        <div className={s.detail_value}>
                            {transactionDetails?.swiftCode}
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.detail_title}>
                            IBAN:
                        </div>
                        <div className={s.detail_value}>
                            {transactionDetails?.ibanCode}
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.detail_title}>
                            Beneficiary Name:
                        </div>
                        <div className={s.detail_value}>
                            {transactionDetails?.beneficiaryName}
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.detail_title}>
                            Beneficiary Country:
                        </div>
                        <div className={s.detail_value}>
                            {transactionDetails?.beneficiaryCountry}
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.detail_title}>
                            Beneficiary City:
                        </div>
                        <div className={s.detail_value}>
                            {transactionDetails?.beneficiaryCity}
                        </div>
                    </div>
                    <div className={s.row}>
                        <div className={s.detail_title}>
                            Beneficiary Address:
                        </div>
                        <div className={s.detail_value}>
                            {transactionDetails?.beneficiaryAddress}
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.info_block}>
                <div className={s.block_title}>
                    Other Details
                </div>
                <div>
                    <div className={s.row}>
                        <div className={s.detail_title}>
                            Purpose:
                        </div>
                        <div className={s.detail_value}>
                            {transactionDetails?.reference}
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.button_block}>
                <div className={s.wrapper}>
                    <CustomBtnPink title={"Back"} isBack onClick={() => navigate(STATEMENT)}/>
                </div>
            </div>
        </div>
    );
};

export default InternationalTransactionInfo;
