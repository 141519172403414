import React, {useState} from 'react';
import {Steps} from 'antd';
import s from './OpenAccount.module.css'
import './index.css'
import TypeOfAccount from "./typeOfAccount/TypeOfAccount";
import OpenAccountForm from "./openAccountForm/OpenAccountForm";
import Success from "./success/Success";
import {motion} from 'framer-motion';

const OpenAccount: React.FC = () => {

    const [typeOfAccount, setTypeOfAccount] = useState("business")
    const [current, setCurrent] = useState(0);

    const steps = [
        {
            title: '',
            content: <TypeOfAccount typeOfAccount={typeOfAccount}
                                    setTypeOfAccount={setTypeOfAccount}
                                    setCurrent={setCurrent}
                                    current={current}
            />
        },
        {
            title: '',
            content: <OpenAccountForm typeOfAccount={typeOfAccount}
                                      setCurrent={setCurrent}
                                      current={current}
            />
        },
        {
            title: '',
            content: <Success/>
        },
    ];

    const items = steps.map((item) => ({key: item.title, title: item.title}));

    const contentStyle: React.CSSProperties = {
        textAlign: 'center',
        color: '#FAFAFA',
        backgroundColor: 'transparent',
        position: "relative"
    };

    return (
        <>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={current === 0 ? s.motion_div : s.motion_div_scrolled }
            >
                <div className={s.center}>
                    <div className={s.steps_wrapper}>
                        <Steps responsive={false} current={current} items={items} className={s.steps_block}
                               direction={"horizontal"}/>
                    </div>
                    <div style={contentStyle}>{steps[current].content}</div>
                </div>

            </motion.div>
        </>
    );
};

export default OpenAccount;
