import {accountsArrayType} from "../store/reducers/UserSlice";
import {useAppSelector} from "./redux";

export const useAccountsForSelect = () => {

    const keys = ['number', 'currency']
    const userAccountsList:accountsArrayType[] = useAppSelector( state => state.userReducer.userAccounts)

    const filteredAccounts = userAccountsList.map((obj: any) => keys.reduce((acc: any, key: any) => {
        acc[key] = obj[key];
        return acc;
    }, {}));

    filteredAccounts.push({number : "ALL"})

    return filteredAccounts
}
