import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import s from './MySelect.module.css'
import {InputLabel} from "@mui/material";

type propsType = {
    item?: (string | null)[]
    width: number
    height: number
    setSelectedItem?: (value: string) => void
    selectedItem?: string
    placeholder?: string
    disabled?: boolean
}

export default function SelectSmall(props: propsType) {

    const handleChange = (event: SelectChangeEvent) => {
        props.setSelectedItem?.(event.target.value);
    };

    return (
        <FormControl sx={{m: 1, minWidth: props.width, height: props.height}} size="small"
                     className={s.my_select_wrapper}>
            <InputLabel className={s.label} id="demo-select-small">{props.placeholder}</InputLabel>
            <Select
                labelId="demo-select-small"
                id="demo-select-small"
                label={props.placeholder}
                defaultValue={"ALL"}
                value={props.selectedItem}
                onChange={handleChange}
                className={s.my_select}
                disabled={props.disabled}
            >
                {
                    props.item?.map((i: any) => {
                        return (
                            <MenuItem key={i} value={i}>{i}</MenuItem>
                        )
                    })
                }
            </Select>
        </FormControl>
    );
}
