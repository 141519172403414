import React, {Dispatch, SetStateAction} from 'react';
import s from './TypeOfAccount.module.css'
import classnames from "classnames";
import business from '../../../assets/img/business_icon.png'
import personal from '../../../assets/img/personal_icon.png'
import CustomBtnPink from "../../../components/ui/customBtnPink/CustomBtnPink";
import CustomBtnTransparent from "../../../components/ui/customBtnTransparent/CustomBtnTransparent";
import {useNavigate} from "react-router-dom";

type PropsType = {
    typeOfAccount: string
    setTypeOfAccount: (type: string) => void
    setCurrent: Dispatch<SetStateAction<number>>
    current: number
}

const TypeOfAccount = (props: PropsType) => {

    const handleChangeAccountTypeByClick = (type: string) => {
        props.setTypeOfAccount(type)
    }
    const navigate = useNavigate()

    return (
        <div>
            <div className={s.title}>
                Type of the account
            </div>
            <div className={s.choose_account_type_block}>
                <div className={classnames(s.business_account,
                    props.typeOfAccount === "business" ? s.active : s.inactive)}
                     onClick={() => handleChangeAccountTypeByClick("business")}>
                    { props.typeOfAccount === "business"
                        ? <div className={s.circle_check_block}>
                            <div className={s.circle_check}></div>
                        </div>
                        : <></>
                    }

                    <div className={s.content}>
                        <img src={business} alt=""/>

                        <div className={s.card_title}>
                            Business <br/>
                            Account
                        </div>
                    </div>

                </div>
                <div className={classnames(s.personal_account,
                    props.typeOfAccount === "personal" ? s.active : s.inactive)}
                     onClick={() => handleChangeAccountTypeByClick("personal")}>
                    { props.typeOfAccount === "personal"
                        ? <div className={s.circle_check_block}>
                            <div className={s.circle_check}></div>
                        </div>
                        : <></>
                    }

                    <div className={s.content}>
                        <img src={personal} alt=""/>

                        <div className={s.card_title}>
                            Personal <br/>
                            Account
                        </div>
                    </div>

                </div>
            </div>
            <div className={s.buttons_wrapper}>
                <div className={s.buttons_block}>
                    <div className={s.button_back}>
                        <CustomBtnTransparent title={"Back"} onClick={() => navigate(-1)} style={{background: "#122932"}}/>
                    </div>
                    <div className={s.button_next}>
                        <CustomBtnPink title={"Next"} onClick={() => props.setCurrent(props.current + 1)}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TypeOfAccount;
