import React from 'react';
import s from "./TradeFinance.module.css";
import p2p_money from "../../assets/img/p2p_money.png";
import p2p_money_mobile from "../../assets/img/mobile/p2p_money_mobile.png";
import {motion} from 'framer-motion';
import classnames from "classnames";

const TradeFinance = () => {
    return (
        <div className={s.wrapper}>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={s.motion_div}
            >
                <div className={s.content}>
                    <div className={s.title}>
                        Trade Finance
                    </div>
                </div>

                <div className={s.color_background}>
                    <div className={s.content}>
                        <div className={s.who_we_desc_block}>
                            <div className={s.who_we_description}>
                                <div>
                                    In foreign trade you are constantly confronted with challenges. Contractual and
                                    punctual
                                    delivery, payment security, and counterparty credit risk on top of political and
                                    economic
                                    uncertainty. With GBH Coriolis Bank’s products and services, we help you to hedge
                                    the
                                    risks
                                    associated with your global payments exposure.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.content}>
                    <div className={s.flex}>
                        <div className={s.left}>
                            <div className={s.mb}>
                                All the while, you will benefit from our private banking touch. As a niche player, we
                                are
                                able to advise instruments directly or through corresponding banks worldwide so that
                                documents
                                are endorsed by prime money center banks.
                            </div>
                        </div>
                        <div className={s.right}>
                            As a GBH Coriolis Bank corporate account holder, you are guaranteed direct contact with a
                            Trade
                            Finance and Documentary Credit expert. This way our clients have their instruments endorsed
                            by
                            prime banks without the undoubted inconvenience of having to deal with the large
                            institutions.
                        </div>
                    </div>

                    <div className={s.services_list_block}>
                        <div className={s.services_list_block_title}>
                            Trade finance clients come from various backgrounds with different needs. The instruments
                            that
                            clients require can be differentiated in:
                        </div>
                        <div className={classnames(s.flex, s.short_block)}>
                            <div className={classnames(s.left, s.short)}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text_short}>
                                        Their instrument size
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text_short}>
                                        Instrument duration
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text_short}>
                                        Payment structure
                                    </div>
                                </div>
                            </div>
                            <div className={classnames(s.right, s.right_short_block)}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text_short}>
                                        Rating of issuing bank
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text_short}>
                                        Rating of advising bank
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text_short}>
                                        The relationship the client maintains with their banks
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={s.flex}>
                        <div className={s.left}>
                            <div className={s.mb}>
                                We are also aware that the arena of Trade Finance can be complicated to understand for
                                clients who are more focused in their manufacturing/commodities business. GBH Coriolis
                                Bank
                                works smoothly with brokers and representatives of those clients to best arrive at a
                                dynamic
                                solution.
                            </div>
                        </div>
                        <div className={s.right}>
                            Ultimately, we always maintain a client-focused vision to arrive and provide the best
                            solution
                            for the client and find a regulation-compliant solution.
                        </div>
                    </div>

                    <div className={s.services_list_block}>
                        <div className={s.services_list_block_title}>
                            Our Trade Finance division focuses on providing clients with the best solution to their
                            unique
                            needs. This involves providing issuance, collection and endorsement of, but not limited to,
                            the
                            following:
                        </div>
                    </div>

                    <div className={s.cards_wrapper}>
                        <div className={s.flex}>
                            <div className={s.card_01}>
                                <div className={s.card_number}>
                                    01.
                                </div>
                                <div className={s.card_title}>
                                    Bank Guarantee (BG)
                                </div>
                                <div className={s.card_text}>
                                    A Bank Guarantee proves to your international trading partners that you are
                                    dependable.
                                    Our offer includes direct or indirect guarantees, payment guarantees, bank
                                    guarantees
                                    and delivery and performance guarantees, depending on what you want to secure.
                                    Providing
                                    your customers and contractors with security gives you a competitive advantage. For
                                    each
                                    transaction we will tailor-make a guarantee with you. Alternatively, we will make
                                    use of
                                    the standardized formulations of the ICC.
                                </div>
                            </div>
                            <div className={s.card_02}>
                                <div className={s.card_number}>
                                    02.
                                </div>
                                <div className={s.card_title}>
                                    Letter of Credit (LC)
                                </div>
                                <div className={s.card_text}>
                                    As a bank we are a specialist in niche and emerging markets. We provide customized
                                    solutions to multi-jurisdictional entities, individuals, and families to protect and
                                    enhance wealth. We enable them to execute business transactions across the globe in
                                    a
                                    secure, efficient and transparent manner. As a trusted partner for modern offshore
                                    banking, our solutions are based on the latest and secure technological platforms.
                                    We
                                    support your global success and ensure your international transactions reach their
                                    destination at all times.
                                </div>
                            </div>
                        </div>
                        <div className={s.card_03}>
                            <div>
                                <div className={s.card_number}>
                                    03.
                                </div>
                                <div className={s.card_title}>
                                    Standby Letter of Credit (SBLC)
                                </div>
                                <div className={s.card_text}>
                                    A Standby Letter of credit is a special type of LC that works as a standby guarantee
                                    to
                                    the seller. If the buyer fails to meet his obligation, the bank will cover the
                                    payment;
                                    that’s why It’s called “standby”. If the seller requires payment advance of the
                                    goods or
                                    services, the standby letter of credit can act as a payment guarantee. The advance
                                    payment standby letter of credit protects against one party’s failure to pay the
                                    other
                                    party’s advance payment.It is not drawn down unless some unwanted incident like a
                                    buyer’s default or bankruptcy.
                                </div>
                            </div>
                            <div className={s.coins_img_block}>
                                <img className={s.p2p_money} src={p2p_money} alt=""/>
                                <img className={s.p2p_money_mobile} src={p2p_money_mobile} alt=""/>
                            </div>

                        </div>

                    </div>

                    <div className={s.flex}>
                        <div className={s.left}>
                            <div className={s.mb}>
                                GBH Coriolis Bank is proud to offer its trade finance correspondent network and
                                relationship
                                to its clients. We are able to advise instruments directly or through prime
                                corresponding
                                banks worldwide.
                            </div>
                        </div>
                        <div className={s.right}>
                            With a flexible team, our Trade Finance Desk creates innovative trade finance solutions for
                            complex needs. Our flexibility and specialist team lets us offer very competitive rates as
                            compared to the market.
                        </div>
                    </div>

                    <div className={s.services_list_block}>
                        <div className={s.services_list_block_title}>
                            The cost of an LC/BG depends on various factors:
                        </div>
                        <div className={s.flex}>
                            <div className={s.left}>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}/>
                                    <div className={s.line_text}>
                                        Size of the Instrument (value and currency)
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}/>
                                    <div className={s.line_text}>
                                        Duration for which the Instrument must be open
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}/>
                                    <div className={s.line_text}>
                                        The format in which the instrument must be communicated (SWIFT message type, KTT
                                        Telex or paper based)
                                    </div>
                                </div>
                            </div>
                            <div className={s.right}>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}/>
                                    <div className={s.line_text}>
                                        If the instrument needs to be advised or confirmed from one of our partner
                                        institutions
                                        or a Prime Money Center Bank
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}/>
                                    <div className={s.line_text}>
                                        Verbiage of Instrument
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className={s.color_background}>
                    <div className={s.content}>
                        <div className={s.who_we_desc_block}>
                            <div className={s.who_we_description}>
                                <div>
                                    <span className={s.white_bold}>At GBH Coriolis Bank</span> we require that fees for
                                    the
                                    instruments are paid before we issue
                                    the instrument. However, in order to provide protection to clients we always offer a
                                    free draft of the LC/BG to the client before requiring any fees.
                                </div>
                                <div>
                                    This way the client may present the draft to the other party’s bank before we go
                                    ahead
                                    with official bank-to-bank communication. This also ensures that the instrument is
                                    suitable for the exact requirements of our clients.
                                </div>
                                <div>
                                    Once fees are received the instrument (LC/BG) is issued within 48 hours. Certain
                                    factors
                                    such as time zones come into play when determining exact turn around time.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.content}>
                    <div className={s.gradient}>
                        <div className={s.gradient_title}>
                            Issue of Trade Finance Instruments is subject to various internationally agreed rules,
                            including:
                        </div>
                        <div className={s.flex}>
                            <div className={s.left}>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}></div>
                                    <div className={s.gradient_text}>
                                        The rules of documentary credits
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}></div>
                                    <div className={s.gradient_text}>
                                        Publication 600 of the International Chamber of Commerce
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}></div>
                                    <div className={s.gradient_text}>
                                        The Uniform Collection Rules (UCR)
                                    </div>
                                </div>
                            </div>
                            <div className={s.right}>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}></div>
                                    <div className={s.gradient_text}>
                                        Publication 552 of the International Chamber of Commerce
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle_blue}></div>
                                    <div className={s.gradient_text}>
                                        The International Standby Practices ISP 98 rules
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={s.gradient_footer}>
                            <span className={s.bold}>Important Note:</span> Currently, due to unprecedented high demand,
                            our Trade Finance Desk is only
                            accepting new trade finance business from our Commercial Account Holders who have first
                            completed onboarding due diligence with a <span className={s.bold_italic}>Multi-Currency Account (MCA)</span> with
                            a minimum funded
                            cash balance of <span className={s.bold_italic}>50,000 USD/EUR/GBP</span>. We unfortunately
                            cannot provide nor discuss our Trade
                            Finance Services with persons who are not existing clients of our bank adhering to the
                            minimum
                            stated.
                        </div>
                    </div>
                </div>
            </motion.div>

        </div>
    );
};

export default TradeFinance;
