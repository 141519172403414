import * as React from 'react';
import Stack from '@mui/material/Stack';
import TextField, {TextFieldProps} from '@mui/material/TextField';
// @ts-ignore
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
// @ts-ignore
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
// @ts-ignore
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import s from './MyDatePicker.module.css'
import {useEffect, useState} from "react";

type PropsType = {
    value: Date | null,
    handleDateChange: any
    onChange?: any
    id?:string
    name?:string
    placeholder?:string
    openingForm?: boolean
    type?: string
    required?: boolean
}
export default function MaterialUIPickers(props: PropsType) {

    const [isMobile, setIsMobile] = useState(false)

    const handleChange = (newValue: any) => {
        props.handleDateChange(newValue.getTime())
    }

    useEffect(() => {

        if (window.innerWidth < 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }

    }, [])

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Stack spacing={3} width={props.openingForm ? "auto" : (isMobile ? 343 : 260)} height={isMobile ? 48 : 58}>
                <DesktopDatePicker className={s.date_picker}
                                   label={props.placeholder}
                                   inputFormat="dd/MM/yyyy"
                                   value={props.value}
                                   onChange={handleChange}

                                   renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) =>
                                       <TextField {...params} className={s.field_style} required={props.required}/>}
                />
            </Stack>
        </LocalizationProvider>
    );
}
