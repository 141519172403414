import React from 'react';
import s from "./MyPopupNewAccount.module.css";
import CustomBtnPink from "../customBtnPink/CustomBtnPink";

type PropsType = {
    setNewAccountPopupIsShow: (value: boolean) => void
}

const MyPopupNewAccount = (props: PropsType) => {
    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <div className={s.title}>
                    New Account
                </div>
                <div className={s.subtitle}>
                    Please send your request to open <br/>
                    a new account by e-mail
                </div>
                <a className={s.mail_link} href="mailto:info@gbhcoriolis.com">info@gbhcoriolis.com</a>
                <div className={s.btns_block}>
                    <CustomBtnPink title={"Ok"}
                                   onClick={() => props.setNewAccountPopupIsShow(false)}/>
                </div>
            </div>
        </div>
    );
};

export default MyPopupNewAccount;
