import React from 'react';
import s from "./Success.module.css";
import CustomBtnPink from "../../../components/ui/customBtnPink/CustomBtnPink";
import {useNavigate} from "react-router-dom";
import {HOME} from "../../../router/Constants";
import {userSlice} from "../../../store/reducers/UserSlice";
import {useAppDispatch} from "../../../hooks/redux";

const Success = () => {

    const navigate = useNavigate()

    const {setMessageSentSuccess} = userSlice.actions
    const dispatch = useAppDispatch()

    const redirect = () => {
        navigate(HOME)
        dispatch(setMessageSentSuccess(null))
    }

    return (
        <div className={s.wrapper}>
            <div className={s.content}>

                <div className={s.title}>
                    Success
                </div>
                <div className={s.subtitle}>
                    Your application will be reviewed within 2-3 days, then our specialists will contact you using the provided information
                </div>

                <div className={s.buttons_wrapper}>
                    <div className={s.buttons_block}>
                        <div className={s.button_next}>
                            <CustomBtnPink title={"Go To Main Page"} onClick={() => redirect()} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Success;
