import React from 'react';
import s from "./DigitalAssetsBanking.module.css";
import coins from "../../assets/img/coins.png";
import CustomBtnPink from "../../components/ui/customBtnPink/CustomBtnPink";
import {useNavigate} from "react-router-dom";
import {OPEN_ACCOUNT} from "../../router/Constants";
import {motion} from 'framer-motion';

const DigitalAssetsBanking = () => {

    const navigate = useNavigate()

    return (
        <div className={s.wrapper}>

            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={s.motion_div}
            >

                <div className={s.content}>

                    <div className={s.title}>
                        digital assets banking
                    </div>

                    <div className={s.who_we_desc_block}>
                        <div className={s.who_we_description}>
                            <div>
                                Digital assets are part of our DNA, just like the personal contact with our clients. We
                                are
                                the leading bank for combining crossover between the crypto world and the securely
                                regulated
                                offshore banking environment.
                            </div>
                        </div>
                    </div>

                    <div className={s.coins_block}>

                        <div className={s.coins_img_block}>
                            <img src={coins} alt=""/>
                        </div>
                        <div className={s.coins_description_block}>
                            We provide comprehensive banking services for both established and startup companies from
                            the
                            blockchain and cryptocurrency world, as well as offering private banking services to high
                            net
                            worth individuals who are trading or investing in crypto assets.
                        </div>


                    </div>
                </div>

                <div className={s.content}>
                    <div className={s.flex}>
                        <div className={s.left}>
                            <div className={s.mb}>
                                Crypto-savvy companies, trustees and private investors can invest in both conventional
                                and
                                crypto assets, while at the same time allowing them to enjoy all of the benefits of
                                day-to-day
                                account and professional payment transaction management, as well as a securities custody
                                account for trading in shares, investment funds, bonds and precious metals.
                            </div>
                        </div>
                        <div className={s.right}>
                            Based on our team’s many years of experience in this area, we fully understand the
                            underlying
                            technology as well as the various business models – from blockchain start-ups to established
                            crypto exchanges and crypto brokers.
                        </div>
                    </div>

                    <div className={s.services_list_block}>
                        <div className={s.services_list_block_title}>
                            Our services in Regulated Digital Assets Banking:
                        </div>
                        <div className={s.flex}>
                            <div className={s.left_list_block}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_desc}>
                                        <div className={s.line_title}>
                                            Multi-Currency Bank Accounts (MCA)
                                        </div>
                                        <div className={s.line_text}>
                                            As a fully regulated bank, the security of your assets is our core business.
                                            We are just as prudent with your crypto assets as we are with conventional
                                            assets. We offer free conversions between selected USD stablecoins and fiat
                                            USD,
                                            allowing you to operate them interchangeably in your account. We also
                                            support
                                            Bitcoin as a currency within the MCA. Benefit from professional management
                                            of
                                            your payment transactions by an experienced back-office team.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_desc}>
                                        <div className={s.line_title}>
                                            OTC Crypto trading
                                        </div>
                                        <div className={s.line_text}>
                                            Trade leading cryptocurrencies with us on a spot basis. You can call our
                                            trading
                                            desk for quotes any time and settle the transactions via your MCA.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={s.right_list_block}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_desc}>
                                        <div className={s.line_title}>
                                            Escrow account
                                        </div>
                                        <div className={s.line_text}>
                                            We support third party OTC fiat-crypto transactions by providing trusted
                                            intermediary
                                            services in a secure, regulated offshore environment. Both parties must
                                            onboard
                                            and open MCAs with our Bank, at which point an escrow agreement can be set
                                            up for
                                            a major OTC transaction. Fiat funds will be deposited in an MCA allowing
                                            them to
                                            be released to the counterparty in real time as soon as the crypto
                                            transaction
                                            is confirmed on the blockchain. Contact us to discuss escrow fees.
                                        </div>
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_desc}>
                                        <div className={s.line_title}>
                                            Business account for Digital Assets companies
                                        </div>
                                        <div className={s.line_text}>
                                            Lombard loans and mortgages secured by digital assets, securities and cash
                                            balances
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={s.gradient_block}>
                        <div className={s.open_text}>
                            All clients will be allocated a private banker so they know immediately who to contact
                            whenever
                            any banking services are needed. Our private bankers speak most global languages.
                        </div>
                        <div className={s.open_text}>
                            You can initiate the account opening process online to see our services for yourself, or
                            find
                            out more about our Blockchain Banking offer by booking an online appointment with our
                            International
                            Digital Assets Banking team today!
                        </div>
                        <div className={s.btn_block}>
                            <div className={s.btn_wrapper}>
                                <CustomBtnPink title={"Open Account"} onClick={() => navigate(OPEN_ACCOUNT)}/>
                            </div>
                        </div>
                    </div>

                </div>
            </motion.div>

        </div>
    );
};

export default DigitalAssetsBanking;
