import React from 'react'
import {userSlice} from "../../../store/reducers/UserSlice";
import {useAppDispatch} from "../../../hooks/redux";
import failed from "../../../assets/img/failed_circle.png";
import CustomBtnPink from "../customBtnPink/CustomBtnPink";
import s from '../MyPopupTransactionRejected/MyPopupTransactionRejected.module.css'

// @ts-ignore
const MyPopupChangePasswordRejected = () => {
    const {setChangePasswordSuccess} = userSlice.actions

    const dispatch = useAppDispatch()

    const closePopup = () => {
        dispatch(setChangePasswordSuccess(null))
    }

    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <img src={failed} alt=""/>
                <div className={s.title}>
                    Failed
                </div>
                <div className={s.subtitle}>
                    Your request was rejected. Please try again
                </div>
                    <div className={s.btn_wrapper}>
                        <CustomBtnPink title={"Try Again"} onClick={() => closePopup()}/>
                    </div>
            </div>
        </div>
    );
};

export default MyPopupChangePasswordRejected;
