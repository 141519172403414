import React, {useEffect, useState} from 'react';
import s from "./IntraTransfers.module.css";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import MySelectAccounts from "../../components/ui/MySelectAccounts/MySelectAccounts";
import {sendIntraTransfer} from "../../store/reducers/ActionCreators";
import {useAccountsForSelect} from "../../hooks/useAccountsForSelect";
import {useFormik} from "formik";
import CustomBtnPink from "../../components/ui/customBtnPink/CustomBtnPink";
import CustomCheckbox from "../../components/ui/customCheckbox/CustomCheckbox";
import classnames from "classnames";
import * as yup from "yup";

const validationSchema = yup.object({
    amount: yup.number().required('Required field'),
})

const IntraTransfers = () => {

    const dispatch = useAppDispatch()

    const userToken: string = useAppSelector( state => state.userReducer.user.userToken)
    const [checkedTerms, setCheckedTerms] = useState<boolean>(false);
    const [checkedError, setCheckedError] = useState<boolean>(false);
    const [selectedAccountCurrency, setSelectedAccountCurrency] = useState("All");
    const [isMobile, setIsMobile] = useState(false)
    const accountsForSelect = useAccountsForSelect()
    const formikInternal = useFormik({
        initialValues: {
            fromAccount: "",
            toAccount: "",
            amount: undefined,
            reference: ""
        },
        validationSchema,
        onSubmit: values => {
            if (!checkedTerms) {
                setCheckedError(true)
                return
            }
            dispatch(sendIntraTransfer(userToken, values))
        },
    });

    useEffect(() => {
        accountsForSelect.map((account) => {
            if (formikInternal.values.fromAccount === "ALL") {
                setSelectedAccountCurrency("ALL")
                return
            }
            if (account.number === formikInternal.values.fromAccount) {
                setSelectedAccountCurrency(account.currency)
            }
        })
    },[formikInternal.values.fromAccount])

    const handleCheckboxChange = () => {
        setCheckedTerms(!checkedTerms)
        setCheckedError(false)
    }

    useEffect(() => {

        if (window.innerWidth < 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }

    }, [])

    return (
        <div className={s.wrapper}>
            <form onSubmit={formikInternal.handleSubmit}>
                <div className={s.inputs_wrapper}>
                    <div className={s.input_block}>
                        <div className={s.input_title}>
                            From Account
                        </div>
                        <MySelectAccounts item={accountsForSelect}
                                          width={isMobile ? 255 : 322}
                                          height={isMobile ? 40 : 58}
                                          id="fromAccount"
                                          name="fromAccount"
                                          onAccountSelectHandler={formikInternal.handleChange}
                                          selectedItem={formikInternal.values.fromAccount}
                        />
                    </div>

                    <div className={s.input_block_currency}>
                        <div className={s.input_title}>
                            Currency
                        </div>
                        <div className={s.current_currency}>
                            {selectedAccountCurrency}
                        </div>
                    </div>
                </div>
                <div className={s.inputs_wrapper}>
                    <div className={s.input_block}>
                        <div className={s.input_title}>
                            To Account
                        </div>
                        <input
                            id="toAccount"
                            name="toAccount"
                            onChange={formikInternal.handleChange}
                            value={formikInternal.values.toAccount}
                            type="text"
                            className={s.my_input}
                        />
                    </div>
                </div>
                <div className={s.inputs_wrapper}>
                    <div className={s.input_block}>
                        <div className={s.input_title}>
                            Amount
                        </div>
                        <input
                            id="amount"
                            name="amount"
                            onChange={formikInternal.handleChange}
                            value={formikInternal.values.amount}
                            type="text"
                            className={classnames( formikInternal.errors.amount ? s.error_input : "", s.my_input)}
                        />
                        {formikInternal.errors.amount && <div className={s.error}>numbers only</div>}
                    </div>

                    <div className={s.input_block_currency}>
                        <div className={s.input_title}>
                            Currency
                        </div>
                        <div className={s.current_currency}>
                            {selectedAccountCurrency}
                        </div>
                    </div>
                </div>
                <div className={s.input_block}>
                    <div className={s.input_title}>
                        Purpose
                    </div>
                    <textarea
                        id="reference"
                        name="reference"
                        onChange={formikInternal.handleChange}
                        value={formikInternal.values.reference}
                        className={s.my_textarea}
                    />
                </div>
                <div className={s.terms_block}>
                    <CustomCheckbox handleChange={handleCheckboxChange}
                                    isChecked={checkedTerms}
                    />
                    <span className={checkedError ? s.checked_error : ''}>I agree with GBH Coriolis</span>
                    <span className={s.pink}>
                        Terms and Conditions
                    </span>
                </div>
                <div className={s.transfer_button_wrapper}>
                    <div className={s.transfer_button_block}>
                        <CustomBtnPink title={"Transfer"} type={"submit"}/>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default IntraTransfers;
