import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import s from './MySelectAccounts.module.css'
import {ChangeEvent} from "react";

type propsType = {
    item?: (string | null)[]
    width: number
    height: number
    onAccountSelectHandler?: { (e: ChangeEvent<any>): void; <T_1 = string | ChangeEvent<any>>(field: T_1): T_1 extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void; }
    selectedItem?: string
    id?:string
    name?:string
    disabled?: boolean
}

export default function MySelectAccounts(props: propsType) {
    return (
        <FormControl sx={{ m: 1, minWidth: props.width, height: props.height }}  size="small" className={s.my_select_wrapper}>
            <Select
                labelId="demo-select-small"
                id={props.id}
                name={props.name}
                defaultValue={"ALL"}
                value={props.selectedItem}
                className={s.my_select}
                onChange={props.onAccountSelectHandler}
                disabled={props.disabled}
            >
                {
                    props.item?.map((i: any) => <MenuItem  key={i.number} data-currency={i.currency} value={i.number}>{i.number}</MenuItem>)
                }
            </Select>
        </FormControl>
    );
}
