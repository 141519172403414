import React from 'react'
import s from './MyPopupTransactionSuccess.module.css'
import {useNavigate} from "react-router-dom";
import {STATEMENT} from "../../../router/Constants";
import {userSlice} from "../../../store/reducers/UserSlice";
import {useAppDispatch} from "../../../hooks/redux";
import success from '../../../assets/img/check_circle_big.png'
import CustomBtnPink from "../customBtnPink/CustomBtnPink";
import CustomBtnTransparentPersonalAccount
    from "../customBtnTransparentPersonalAccount/CustomBtnTransparentPersonalAccount";

const MyPopupTransactionSuccess = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const closePopup = () => {
        dispatch(userSlice.actions.setTransactionWasSendSuccess(null))
    }

    const closeAndRedirect = () => {
        dispatch(userSlice.actions.setTransactionWasSendSuccess(null))
        navigate(STATEMENT)
    }

    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <img src={success} alt=""/>
                <div className={s.title}>
                    Success
                </div>
                <div className={s.subtitle}>
                    Your request has been sent
                </div>
                <div className={s.btns_block}>
                    <div className={s.btn_wrapper}>
                        <CustomBtnPink title={"Ok"} onClick={() => closePopup()}/>
                    </div>
                    <div className={s.btn_wrapper}>
                        <CustomBtnTransparentPersonalAccount title={"Go to Statements"} onClick={() => closeAndRedirect()}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyPopupTransactionSuccess;
