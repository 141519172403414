import React from 'react';
import s from './MobileLegalInformation.module.css'
import {Link} from "react-router-dom";
import mwali_logo from "../../assets/img/Mwali_security_logo.png";
import {motion} from 'framer-motion';

const MobileLegalInformation = () => {
    return (
        <div className={s.wrapper}>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={s.motion_div}
            >
                <div className={s.container}>
                    <div className={s.title}>
                        Legal Information
                    </div>
                    <div className={s.docs_block}>
                        <a className={s.doc_link} target="_blank" href={'/legalDocuments/Privacy_Policy.pdf'}>Privacy Policy</a>
                        <a className={s.doc_link} target="_blank" href={'/legalDocuments/Website_Terms_and_Conditions.pdf'}>Website
                            Terms and Conditions</a>
                        <a className={s.doc_link} target="_blank" href={'/legalDocuments/Corporate_Governance.pdf'}>Corporate
                            Governance</a>
                        <a className={s.doc_link} target="_blank" href={'/legalDocuments/Cookie_Policy.pdf'}>Cookie Policy</a>
                        <a className={s.doc_link} target="_blank" href={'/legalDocuments/Bank_Transaction_Terms.pdf'}>Bank Transactions
                            Terms</a>
                        <a className={s.doc_link} target="_blank" href={'/legalDocuments/Debit_Card_Terms.pdf'}>Debit Card Terms</a>
                        <a className={s.doc_link} target="_blank" href={'/'}>Standard Fees Tariff</a>
                    </div>
                    <div className={s.mwali_block}>
                        <img src={mwali_logo} className={s.logo} alt=""/>
                        <div className={s.license_number}>
                            B20070016
                        </div>
                        <Link to={'https://mwaliregistrar.com/list_of_entities'} about="_blank"
                              className={s.mwali_link}>
                            Link to license
                        </Link>
                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default MobileLegalInformation;
