import React from 'react';
import s from './CustomCard.module.css'

type PropsType = {
    title: string,
    short: string,
    description: string,
}

const CustomCard = (props: PropsType) => {
    return (
        <div className={s.card}>
            <div className={s.background_gradient}></div>
            <div className={s.layout}>
                <div className={s.title_block}>
                    <div className={s.short_block}>
                        {props.short}
                    </div>
                    <div className={s.title}>
                        {props.title}
                    </div>
                </div>
                <div className={s.text}>
                    {props.description}
                </div>
            </div>
            <div className={s.arrowBlock}>
                <div className={s.arrow}/>
            </div>
        </div>
    );
};

export default CustomCard;
