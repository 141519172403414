import React, {CSSProperties} from 'react';
import s from './CustomBtnPink.module.css'
import plusImg from '../../../assets/img/add_account_plus.png'
import arrBack from '../../../assets/img/arrow-left.png'
import attach from '../../../assets/img/paperclip.png'

type PropsType = {
    title: string,
    onClick?: (e?: any) => void;
    type?: string
    isAddAccount? :boolean
    isBack? :boolean
    isAttach? :boolean
    style?: CSSProperties
}
const CustomBtnPink:React.FC<PropsType> = ({isAttach,isBack,isAddAccount, title, type, ...props}) => {
    return (
        <button className={s.my_btn} {...props}>
            {
                isAddAccount
                ? <img src={plusImg} alt={""}/>
                : <></>
            }
            {
                isBack
                    ? <img src={arrBack} alt={""}/>
                    : <></>
            }

            {
                isAttach
                    ? <img src={attach} alt={""}/>
                    : <></>
            }
            {title}
        </button>
    );
};

export default CustomBtnPink;
