import React from 'react'
import check from '../../../assets/img/check_circle_big.png'
import {useAppDispatch} from "../../../hooks/redux";
import CustomBtnPink from "../customBtnPink/CustomBtnPink";
import {userSlice} from "../../../store/reducers/UserSlice";
import s from './MyPopupSendSuccess.module.css'

const MyPopupSendSuccess = () => {

    const {setMessageSentSuccess} = userSlice.actions

    const dispatch = useAppDispatch()

    const closePopup = () => {
        dispatch(setMessageSentSuccess(null))
    }

    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <img className={s.check} src={check} alt=""/>
                <div className={s.title}>
                    Success
                </div>
                <div className={s.subtitle}>
                    We have received your request. <br/>
                    Our specialist will contact you.
                </div>
                <div className={s.btns_block}>
                    <CustomBtnPink title={"Ok"} onClick={() => {closePopup()}}/>
                </div>
            </div>
        </div>
    );
};

export default MyPopupSendSuccess;
