import React, {useEffect, useState} from 'react';
import s from './Footer.module.css'
import mwali_logo from '../../assets/img/Mwali_security_logo.png'
import mailIcon from '../../assets/img/icon_mail.png'
import addressIcon from '../../assets/img/addressIcon.png'
import phoneIcon from '../../assets/img/icon_phone.png'
import {Link, NavLink, useLocation} from "react-router-dom";
import {publicRoutes} from "../../router/PublicRoutes";
import {HOME} from "../../router/Constants";
import whatsapp from "../../assets/img/telegramm.png";

const Footer = () => {

    const location = useLocation()
    const [isFooterHidden, setIsFooterHidden] = useState(true)

    useEffect(() => {
        if (location.pathname.includes("open_account")) {
            setIsFooterHidden(true)
        } else if (location.pathname.includes("log_in")) {
            setIsFooterHidden(true)
        } else {
            setIsFooterHidden(false)
        }
    }, [location.pathname])

    return (
        <div className={s.wrapper}>
            {
                isFooterHidden
                    ? <div className={s.content_small}>
                        <div className={s.footer_block}>
                            <div className={s.copyright}>
                                © 2023 GBH Coriolis Bancorp
                            </div>
                        </div>
                    </div>
                    : <div className={s.content}>
                        <div className={s.footer_block}>
                            <div className={s.title}>
                                License Number
                            </div>
                            <img src={mwali_logo} alt=""/>
                            <div className={s.license_number}>
                                B20070016
                            </div>
                            <Link to={'https://mwaliregistrar.com/list_of_entities'} about="_blank"
                                  className={s.mwali_link}>
                                Link to license
                            </Link>
                        </div>
                        <div className={s.footer_block}>
                            <div className={s.title}>
                                Legal Information
                            </div>
                            <div className={s.docs_block}>
                                <a className={s.doc_link} target="_blank" href={'/legalDocuments/Privacy_Policy.pdf'}>Privacy
                                    Policy</a>
                                <a className={s.doc_link} target="_blank"
                                   href={'/legalDocuments/Website_Terms_and_Conditions.pdf'}>Website Terms and
                                    Conditions</a>
                                <a className={s.doc_link} target="_blank"
                                   href={'/legalDocuments/Corporate_Governance.pdf'}>Corporate Governance</a>
                                <a className={s.doc_link} target="_blank" href={'/legalDocuments/Cookie_Policy.pdf'}>Cookie
                                    Policy</a>
                                <a className={s.doc_link} target="_blank"
                                   href={'/legalDocuments/Bank_Transaction_Terms.pdf'}>Bank Transactions Terms</a>
                                <a className={s.doc_link} target="_blank" href={'/legalDocuments/Debit_Card_Terms.pdf'}>Debit
                                    Card Terms</a>
                                <a className={s.doc_link} href={'#'}>Standard Fees Tariff</a>
                            </div>
                        </div>
                        <div className={s.footer_block}>
                            <div className={s.title}>
                                Our Services
                            </div>
                            <div className={s.links_block}>
                                <NavLink className={s.footer_link} to={HOME} reloadDocument>Home</NavLink>
                                {
                                    publicRoutes.map((item) => {
                                        return (
                                            <NavLink to={item.path} className={s.footer_link} key={item.path}
                                                     reloadDocument>
                                                {item.title}
                                            </NavLink>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={s.footer_block}>
                            <div className={s.title}>
                                Contact Info
                            </div>
                            <div className={s.address_block}>
                                <img src={addressIcon} width={24} height={24} alt=""/>
                                <div className={s.mail}>Representative Office: <br/>
                                    Room 2, 3rd floor, Junction mall, Ngong Road, Nairobi, Kenya
                                </div>
                            </div>

                            <div className={s.mail_block}>
                                <img src={mailIcon} alt=""/>
                                <a className={s.mail} href="mailto:info@gbhcoriolisbank.com">info@gbhcoriolisbank.com</a>
                            </div>
                            <div className={s.mail_block}>
                                <img src={phoneIcon} alt=""/>
                                <div className={s.mail}>+442045772159</div>
                            </div>
                            <div className={s.swift_block}>SWIFT: CLORKMK1</div>
                            <div className={s.swift_block}>Telex: (942) KO CORIOL</div>
                            <div className={s.flex}>
                                <div className={s.whatsapp_wrapper}>
                                    <a style={{marginTop: 3, marginLeft:2}} href="https://wa.me/+37254640735" target="_blank">
                                        <img src={whatsapp} alt=""/>
                                    </a>
                                </div>
                            </div>
                            <div className={s.copyright}>
                                © 2023 GBH Coriolis Bancorp
                            </div>
                        </div>
                    </div>
            }
            <div className={s.content_small_mobile}>
                <div className={s.footer_block}>
                    <div className={s.copyright}>
                        © 2023 GBH Coriolis Bancorp
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
