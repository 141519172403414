import {createSelector} from "@reduxjs/toolkit";
import {filtersType, transactionType} from "./UserSlice";
import {RootState} from "../store";
export const selectAllTransactions = (state: RootState) => state.userReducer.userTransactions
export const selectFilters = (state: RootState) => state.userReducer.filters

export const selectTransactionsByFilter = createSelector<any, any>(
    selectAllTransactions,
    selectFilters,
    (allTransactions: any, selectFilters: filtersType) => {

        let sortedTransactions = [...allTransactions]

        if (selectFilters.accountNumber === "ALL") {
            sortedTransactions = allTransactions
        }

        if (selectFilters.accountNumber !== "ALL") {
            sortedTransactions = sortedTransactions.filter((transaction : transactionType) => transaction.fromAccount === selectFilters.accountNumber)
        }

        if (selectFilters.dateFrom !== null) {
            // @ts-ignore
            sortedTransactions = sortedTransactions.filter((transaction : transactionType) => (transaction.created * 1000) >= selectFilters.dateFrom)
        }

        if (selectFilters.dateTo !== null) {
            // @ts-ignore
            sortedTransactions = sortedTransactions.filter((transaction : transactionType) => (transaction.created * 1000) <= (selectFilters.dateTo + 86400000))
        }

        if (selectFilters.currency === "ALL" && selectFilters.accountNumber === 'ALL' && selectFilters.dateTo === null && selectFilters.dateFrom === null) {
            sortedTransactions = allTransactions
        }

        if (selectFilters.currency !== "ALL") {
            sortedTransactions = sortedTransactions.filter((transaction : transactionType) => transaction.currency === selectFilters.currency)
        }

        return sortedTransactions
    }
)
