import React from 'react';
import s from './AboutUs.module.css'
import puzzles from '../../assets/img/puzzles.png'
import puzzlesMobile from '../../assets/img/mobile/puzzless.png'
import coin from '../../assets/img/bitcoin.png'
import CustomBtnPink from "../../components/ui/customBtnPink/CustomBtnPink";
import {OPEN_ACCOUNT} from "../../router/Constants";
import {useNavigate} from "react-router-dom";
import { motion } from 'framer-motion';

const AboutUs = () => {

    const navigate = useNavigate()

    return (
        <div className={s.wrapper}>

            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={s.motion_div}
            >

                <div className={s.content}>
                    <div className={s.title}>
                        about us
                    </div>
                </div>

                <div className={s.gradient_block}>
                    GBH Coriolis Bank is an international private bank!
                </div>

                <div className={s.content}>
                    <div className={s.flex}>
                        <div className={s.left}>
                            Its two predecessor banks have been a trademark of confidence, reliability and partnership
                            for
                            more than a quarter of a century.
                            The Bank was formed in December 2022 by the merger of two entities: GBH Private Bank, with a
                            long history in Latin America, and Coriolis Banking Corporation, originally set up as the
                            inhouse treasury of a major Asian construction company. It was later subject to a management
                            buy-out financed by a Cayman Island fund. In today’s borderless economy, where money flows
                            where
                            it is treated best, it is no surprise that the two banks teamed up in an offshore finance
                            hub.
                        </div>
                        <div className={s.right}>
                            <div className={s.mb}>
                                The Bank is incorporated in Fomboni, Comoros Islands, and has a team of International
                                Private Bankers working digitally around the world providing almost 24/7 service.
                            </div>
                            <div>
                                We provide flexible financial services to individuals and businesses with an
                                international
                                presence. An offshore account offers flexibility, discretion and in many cases enhanced
                                security. We offer specialized services related to digital assets, including escrow
                                services, and financing of real estate in prime cities and resort areas worldwide.
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.content}>
                    <div className={s.puzzles_block}>
                        <div className={s.flex}>
                            <div className={s.puzzles_img_block}>
                                <img className={s.puzzles_mob} src={puzzlesMobile} alt=""/>
                                <img className={s.puzzles} src={puzzles} alt=""/>
                            </div>
                            <div className={s.puzzles_description_block}>
                                <div className={s.puzzles_description_content}>
                                    Our International Private Banking services are designed for the most discerning
                                    international clients who need a bank that understands them. We are (almost) always
                                    able
                                    to find a regulation-compliant solution.
                                </div>
                                <div className={s.puzzles_description_content}>Our clients are modern, sophisticated global citizens with interests in digital
                                    assets
                                    and/or prime real estate, although the Bank offers all the traditional private
                                    banking
                                    services you would expect. We also support and advise asset managers, family
                                    offices,
                                    fund managers and fiduciaries.
                                </div>
                                <div className={s.puzzles_button_block}>
                                    <div className={s.button_wrapper}>
                                        <CustomBtnPink title={"Sign Up"} onClick={() => navigate(OPEN_ACCOUNT)}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={s.content}>
                    <div className={s.flex}>
                        <div className={s.left}>
                            Meanwhile, our International Trade Finance business grew out of the Coriolis role, created
                            to
                            manage and solve global trade finance documentary needs. With a world-wide scope, we provide
                            global trade finance solutions to Banks, Brokers, Corporates and Individuals.
                        </div>
                        <div className={s.right}>
                            Our financial instruments are used by traders, companies and individuals to meet required
                            trade finance needs. Our global presence and knowledge help provide a solution that is
                            flexible and cognizant of the needs of our clients.
                        </div>
                    </div>
                </div>

                <div className={s.coin_block}>
                    <div className={s.content}>
                        <div className={s.text}>
                            Finally, <span className={s.italic}>GBH Coriolis Bank</span> works with crypto and
                            blockchain companies to provide them with
                            full-service multi-currency banking, asset management, trading and advisory. We are one of
                            the very
                            few offshore regulated financial banks that support blockchain and digital assets.

                            <div className={s.coin_container}>
                                <img src={coin} alt=""/>
                            </div>

                        </div>

                    </div>
                </div>
            </motion.div>
        </div>
    );
};

export default AboutUs;
