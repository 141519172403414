import {useMemo} from "react";

export const useSortedAccounts = (userAccountsList: any, selectedCurrency: any) => {
    return useMemo(() => {

        if (selectedCurrency === "ALL") {
            return userAccountsList
        }

        return userAccountsList.filter((account: any) => {
            return account.currency === selectedCurrency
        })

    }, [userAccountsList, selectedCurrency])
}
