import React, {useState} from "react";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import CustomInput from "../../components/ui/customInput/CustomInput";
import CustomBtnPink from "../../components/ui/customBtnPink/CustomBtnPink";
import MyPopupChangePasswordSuccess
    from "../../components/ui/MyPopupChangePasswordSuccess/MyPopupChangePasswordSuccess";
import {changePasswordThunk} from "../../store/reducers/ActionCreators";
import MyPopupChangePasswordRejected
    from "../../components/ui/MyPopupChangePasswordRejected/MyPopupChangePasswordRejected";
import s from "./ChangePassword.module.css";
import {userSlice} from "../../store/reducers/UserSlice";

export const ChangePassword = () => {
    const dispatch = useAppDispatch()
    const userToken: string = useAppSelector( state => state.userReducer.user.userToken)
    const [password, setPassword] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const {setChangePasswordSuccess} = userSlice.actions
    const isChangePasswordSuccess: boolean | null = useAppSelector( state => state.userReducer.isChangePasswordSuccess)
    const passwordValidation = (password: string) => {
        const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,20}$/;
        return passwordRegex.test(password)
    };

    const handleUpdatePassword = () => {
        if ((password.newPassword === password.confirmPassword) && passwordValidation(password.newPassword)) {
            const dataForPassword = {
                oldPassword: password.oldPassword,
                newPassword: password.newPassword,
            }
            dispatch(changePasswordThunk(userToken, dataForPassword))
            setPassword({
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
            });
        } else {
            dispatch(setChangePasswordSuccess(false))
        }
    };


    return (
        <div className={s.container}>
            {isChangePasswordSuccess && <MyPopupChangePasswordSuccess />}
            {isChangePasswordSuccess === false && <MyPopupChangePasswordRejected />}
            <div className={s.title}>
                Change password
            </div>
            <p className={s.text}>
                The password must consist of eight or more characters of the Latin alphabet, contain uppercase and lowercase letters, numbers. Only alphabets and numbers are allowed in password field.
                Max length is 20 characters.
            </p>
            <div className={s.inputContainer}>
                <div className={s.inputWrap}>
                    <label>Old Password</label>
                    <CustomInput id={"changePassword"} name={"changePassword"} type={"password"}
                                 onChange={(e) =>
                                     setPassword({
                                         ...password,
                                         oldPassword: e.currentTarget.value
                                     })}
                                 value={password.oldPassword}
                    />
                </div>
                <div className={s.inputWrap}>
                    <label>New Password</label>
                    <CustomInput id={"changePasswordNew"} name={"changePasswordNew"} type={"password"}
                                 onChange={(e) =>
                                     setPassword({
                                         ...password,
                                         newPassword: e.currentTarget.value
                                     })}
                                 value={password.newPassword}                    />
                </div>
                <div className={s.inputWrap}>
                    <label>Confirm New Password</label>
                    <CustomInput id={"changePasswordConfirm"} name={"changePasswordConfirm"} type={"password"}
                                 onChange={(e) =>
                                     setPassword({
                                         ...password,
                                         confirmPassword: e.currentTarget.value
                                     })}
                                 value={password.confirmPassword}                    />
                </div>
                <div className={s.btnWrap}>
                <CustomBtnPink  title={"Update"}
                               onClick={handleUpdatePassword} />
                </div>
            </div>
        </div>
    )

}

