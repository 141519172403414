import React, {ChangeEvent} from 'react';
import './index.css'

type PropsType = {
    handleChange: { (e: ChangeEvent<any>): void; <T_1 = string | ChangeEvent<any>>(field: T_1): T_1 extends ChangeEvent<any> ? void : (e: string | ChangeEvent<any>) => void; }
    isChecked: boolean
    id?: string
    name?: string
}

const CustomCheckbox = (props: PropsType) => {
    return (
        <label className={"checkbox_label"}>
            <input
                type="checkbox"
                onChange={props.handleChange}
                id={props.id}
                name={props.name}
                checked={props.isChecked}
            />
            <span
                className={`checkbox ${props.isChecked ? "checkbox--active" : ""}`}
                aria-hidden="true"
            />
        </label>
    );
};

export default CustomCheckbox;
