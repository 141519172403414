import React from 'react';
import s from './NavBar.module.css'
import {NavLink} from "react-router-dom";
import {HOME} from "../../router/Constants";
import {publicRoutes} from "../../router/PublicRoutes";
import './index.css'


const NavBar = () => {
    return (
        <div className={s.wrapper}>
            <NavLink className={s.link} to={HOME}>Home</NavLink>
            {
                publicRoutes.map((item) => {
                    return (
                        <NavLink to={item.path} key={item.path} className={s.link}>{item.title}</NavLink>
                    )
                })
            }
        </div>
    );
};

export default NavBar;
