import React from 'react';
import s from './PrivateBanking.module.css'
import grow from '../../assets/img/grow.png'
import cash from '../../assets/img/cash.png'
import check_circle from '../../assets/img/check_circle.svg'
import {motion} from 'framer-motion';

const PrivateBanking = () => {
    return (
        <div className={s.wrapper}>
            <motion.div
                initial={{opacity: 0, scale: 1}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className={s.motion_div}
            >
                <div className={s.content}>
                    <div className={s.title}>
                        Private Banking
                    </div>

                    <div className={s.cards_wrapper}>
                        <div>
                            <div className={s.card_01}>
                                <div className={s.card_number}>
                                    01.
                                </div>
                                <div className={s.card_text}>
                                    The hallmark of Private Banking is its exemplary, personalized, bespoke service. As
                                    no two clients are the same, flexibility is a cornerstone. Please inform us about
                                    the banking service you require, and your <br/>
                                    GBH Coriolis International <br/>
                                    Private Banker will implement <br/>
                                    it for you.
                                </div>
                                <div className={s.card_bold}>
                                    Challenging tasks do not <br/>
                                    fluster us in the slightest.
                                </div>
                                <div className={s.cash_block}>
                                    <img src={cash} alt=""/>
                                </div>
                            </div>
                            <div className={s.mobile_card_2}>
                                <div className={s.card_number}>
                                    02.
                                </div>
                                <div className={s.card_text}>
                                    We have a long history of financial stability, working with complex, multi-jurisdictional
                                    entities, individuals, and families to protect and enhance wealth. Our personalized
                                    approach to preserving and growing your wealth begins with our core focus on accounts
                                    that offer above-average returns while hedging market risk volatility. We achieve
                                    this by selectively making loans secured against liquid assets and prime real estate
                                    in major cities worldwide. We cater to those who require an international presence,
                                    providing overseas current or savings accounts that offer flexibility, discretion,
                                    and, in many cases, enhanced security and confidentiality.
                                </div>
                                <div>
                                    <img className={s.grow} src={grow} alt=""/>
                                </div>
                            </div>
                            <div className={s.card_03}>
                                <div className={s.card_number}>
                                    03.
                                </div>
                                <div className={s.card_text}>
                                    We take pride in our exceptional specialist expertise in digital assets and blockchain
                                    banking, fund solutions development, tokenization, capital market issues setup, and
                                    conventional banking. Additionally, we offer extensive expertise in the field of HNWI mortgages.
                                </div>
                            </div>
                        </div>
                        <div className={s.card_02}>
                            <div className={s.card_number}>
                                02.
                            </div>
                            <div className={s.card_text}>
                                We have a long history of financial stability, working with complex,
                                multi-jurisdictional
                                entities, individuals, and families to protect and enhance wealth. Our personal approach
                                to
                                preserving and growing your wealth starts with our core focus of accounts featuring
                                above
                                average returns, hedging market risk volatility. We achieve this by making only highly
                                selective
                                loans, secured against liquid assets and prime real estate in major cities worldwide. We
                                offer
                                accounts to those who need an international presence. An overseas current or savings
                                account
                                provides flexibility, discretion and in many cases enhanced security and
                                confidentiality.
                            </div>
                            <div>
                                <img className={s.grow} src={grow} alt=""/>
                            </div>
                        </div>
                    </div>

                    <div className={s.services_list_block}>
                        <div className={s.services_list_block_title}>
                            We offer the following core private banking services, delivered through a secure and cutting
                            edge digital channel:
                        </div>
                        <div className={s.flex}>
                            <div className={s.left}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                        Multi-currency deposit account supporting all major and some exotic currencies
                                        plus
                                        crypto currencies
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                        Custody of digital assets and execution services
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                        Trading and brokerage
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                        Bespoke investment solutions
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                        Foreign Exchange – spot, forward, swap and lending
                                    </div>
                                </div>
                            </div>
                            <div className={s.right}>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                        OTC and Derivatives Instruments
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                        Precious Metals Trading, Custody and Lending
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                        Payments, Transfers, and Credit Cards
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                        Lombard loans and mortgages secured by digital assets, securities and cash
                                        balances
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <div className={s.check_circle}/>
                                    <div className={s.line_text}>
                                        Concierge services
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div className={s.gradient}>
                        <div className={s.gradient_title}>
                            The Private Banking relationship is based on a hub Multi-Currency Account (MCA) The GBH
                            Coriolis
                            Bank Multi-Currency Account offers international clients the ultimate convenience with their
                            foreign currency transactions. Your MCA will enable you to:
                        </div>
                        <div className={s.flex}>
                            <div className={s.left}>
                                <div className={s.line}>
                                    <img src={check_circle} alt=""/>
                                    <div className={s.gradient_text}>
                                        Consolidate and manage foreign receivables and payments more efficiently
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <img src={check_circle} alt=""/>
                                    <div className={s.gradient_text}>
                                        Send and receive wire payments and settle your credit card bills without
                                        currency
                                        conversion
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <img src={check_circle} alt=""/>
                                    <div className={s.gradient_text}>
                                        Conduct transactions in most major world currencies.
                                    </div>
                                </div>
                            </div>
                            <div className={s.right}>
                                <div className={s.line}>
                                    <img src={check_circle} alt=""/>
                                    <div className={s.gradient_text}>
                                        Allow for currency diversification as an investment option including our “Invest
                                        Loan” program
                                    </div>
                                </div>
                                <div className={s.line}>
                                    <img src={check_circle} alt=""/>
                                    <div className={s.gradient_text}>
                                        View balances and activities for all currency accounts online.
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={s.gradient_footer}>
                            All clients will be allocated a private banker so they know immediately who to contact
                            whenever
                            any banking services are needed. Our private bankers speak most global languages. You can
                            initiate the account opening process online, or schedule a call with one of our
                            International
                            Private Banking team today!
                        </div>
                    </div>

                </div>
            </motion.div>
        </div>
    );
};

export default PrivateBanking;
