import React from 'react';
import s from './CustomBtnTransparentPersonalAccount.module.css'

type PropsType = {
    title: string,
    onClick?: () => void;
    isLogout?: boolean
}
const CustomBtnTransparentPersonalAccount:React.FC<PropsType> = ({title, isLogout, ...props}) => {
    return (
        <button className={s.my_btn} {...props}>
            {isLogout
                ? <div className={s.logout_arrow} />
                : <></>
            }
            {title}
        </button>
    );
};

export default CustomBtnTransparentPersonalAccount;
