import React, {Dispatch, SetStateAction} from 'react';
import BusinessForm from "../businessForm/BusinessForm";
import PersonalForm from "../personalForm/PersonalForm";

type PropsType = {
    typeOfAccount: string
    setCurrent: Dispatch<SetStateAction<number>>
    current: number
}

const OpenAccountForm = (props: PropsType) => {

    return (
        <div>
            {
                props.typeOfAccount === "business"
                ? <BusinessForm setCurrent={props.setCurrent}
                                current={props.current}
                    />
                : <PersonalForm setCurrent={props.setCurrent}
                                current={props.current}/>
            }
        </div>
    );
};

export default OpenAccountForm;
