import React from 'react';
import s from "./MobileMenu.module.css";
import closePopup from '../../assets/img/mobile/close.png'
import classnames from "classnames";
import {NavLink, useNavigate} from "react-router-dom";
import {HOME, LOGIN, MOBILE_LEGAL_INFORMATION, OPEN_ACCOUNT} from "../../router/Constants";
import {publicRoutes} from "../../router/PublicRoutes";
import CustomBtnPink from "../ui/customBtnPink/CustomBtnPink";
import CustomBtnTransparent from "../ui/customBtnTransparent/CustomBtnTransparent";

type propsType = {
    isActive: boolean
    setIsActive: (boolean: boolean) => void
}

const MobileMenu = ({isActive, setIsActive}: propsType) => {

    const navigate = useNavigate()

    const redirect = (link: string) => {
        navigate(link)
        setIsActive(false)
    }

    return (
        <div className={classnames(s.myPopup, isActive ? s.active : s.inactive)}>
            <div className={s.close_img_block}>
                <img src={closePopup} className={s.closeImg} alt="" onClick={() => setIsActive(false)}/>
            </div>
            <div className={s.links_block}>
                <NavLink to={HOME} className={s.link} onClick={() => setIsActive(false)}>
                    Home
                </NavLink>
                { publicRoutes.map((item) =>
                    <NavLink key={item.path} className={s.link} to={item.path} onClick={() => setIsActive(false)}>
                        {item.title}
                    </NavLink>
                )}
                <NavLink to={MOBILE_LEGAL_INFORMATION} className={s.link} onClick={() => setIsActive(false)}>
                    Legal information
                </NavLink>
            </div>
            <div className={s.btn_block}>
                <CustomBtnPink title={"Open Account"} onClick={() => redirect(OPEN_ACCOUNT)}/>
            </div>
            <div className={s.btn_block}>
                <CustomBtnTransparent title={"Log In"} isLogin onClick={() => redirect(LOGIN)}/>
            </div>
            <div className={s.mobile_menu_footer}>
                <div className={s.copyright}>
                    © 2023 GBH Coriolis Bancorp
                </div>
            </div>
        </div>
    );
};

export default MobileMenu;
