import React from 'react'
import check from '../../../assets/img/check_circle_big.png'
import {useAppDispatch} from "../../../hooks/redux";
import CustomBtnPink from "../customBtnPink/CustomBtnPink";
import {userSlice} from "../../../store/reducers/UserSlice";
import s from './MyPopupChangePasswordSuccess.module.css'

const MyPopupChangePasswordSuccess = () => {

    const {setChangePasswordSuccess} = userSlice.actions

    const dispatch = useAppDispatch()

    const closePopup = () => {
        dispatch(setChangePasswordSuccess(null))
    }

    return (
        <div className={s.wrapper}>
            <div className={s.content}>
                <img className={s.check} src={check} alt=""/>
                <div className={s.title}>
                    Success
                </div>
                <div className={s.subtitle}>
                    Your password has been changed
                </div>
                <div className={s.btns_block}>
                    <CustomBtnPink title={"Ok"} onClick={() => {closePopup()}}/>
                </div>
            </div>
        </div>
    );
};

export default MyPopupChangePasswordSuccess;
