import React, {useEffect, useState} from 'react';
import s from './Statement.module.css'
import SelectSmall from "../../components/ui/MySelect/MySelect";
import MaterialUIPickers from "../../components/ui/MyDatePicker/MyDatePicker";
import {useAccountsForSelect} from "../../hooks/useAccountsForSelect";
import MySelectAccounts from "../../components/ui/MySelectAccounts/MySelectAccounts";
import {accountsArrayType, userSlice} from "../../store/reducers/UserSlice";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {GetTransactionsListThunk} from "../../store/reducers/ActionCreators";
import approved from "../../assets/img/approved.png"
import pending from "../../assets/img/pending.png"
import denied from "../../assets/img/denied.png"
import CustomBtnPink from "../../components/ui/customBtnPink/CustomBtnPink";
import {Link} from "react-router-dom";
import {INTERNATIONAL_TRANSACTION_INFO} from "../../router/Constants";
import Paginator from "../../components/paginator/Paginator";
import {selectTransactionsByFilter} from "../../store/reducers/selector";
import {useSelector} from "react-redux";

const Statement = () => {

    const dispatch = useAppDispatch()

    const {setDateToFilter, setDateFromFilter, setAccountNumberFilter, setCurrencyFilter} = userSlice.actions
    const userToken: string = useAppSelector(state => state.userReducer.user.userToken)
    const transactionsList = useAppSelector(state => state.userReducer.userTransactions)

    const filteredTransactions = useSelector(selectTransactionsByFilter);
    const accountsForSelect = useAccountsForSelect()
    const userAccountsList: accountsArrayType[] = useAppSelector(state => state.userReducer.userAccounts)
    const [isMobile, setIsMobile] = useState(false)
    const [dateFrom, setDateFrom] = useState(null)
    const [dateTo, setDateTo] = useState<Date | null>(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [accountNumber, setAccountNumber] = useState("ALL")
    const [accountCurrency, setAccountCurrency] = useState("ALL")

    useEffect(() => {
        dispatch(setAccountNumberFilter("ALL"))
    }, [])

    const filterByClick = () => {
        dispatch(setAccountNumberFilter(accountNumber))
        dispatch(setDateToFilter(dateTo))
        dispatch(setDateFromFilter(dateFrom))
        dispatch(setCurrencyFilter(accountCurrency))
    }

    const itemsPerPage = 15

    const getAccountsListCurrency = () => {
        return userAccountsList?.map((acc) => {
            return acc.currency
        })
    }

    const currencyArr = getAccountsListCurrency()
    currencyArr.push("ALL")

    const sliceArrForPagination = (filteredTransactions: any, itemsPerPage: number) => {
        const res = []

        for (let i = 0; i < filteredTransactions.length; i += itemsPerPage) {
            const slice = filteredTransactions.slice(i, i + itemsPerPage)
            res.push(slice)
        }

        return res
    }

    const slicedTransactionsArr = sliceArrForPagination(filteredTransactions, itemsPerPage)

    useEffect(() => {
        dispatch(GetTransactionsListThunk(userToken))
    }, [transactionsList.length])

    const getImgForTransactionStatus = (transactionStatus: string) => {
        if (transactionStatus === "Approved") {
            return <img src={approved} alt=""/>
        }

        if (transactionStatus === "Denied") {
            return <img src={denied} alt=""/>
        }

        if (transactionStatus === "New") {
            return <img src={pending} alt=""/>
        }
    }

    const transactionDirectionIsOut = (direction: string) => {
        if (direction === "OUT") {
            return true
        }
    }

    // const onAccountSelectHandler = (e: any) => {
    //     dispatch(setAccountNumberFilter(e.target.value))
    // }

    useEffect(() => {

        if (window.innerWidth < 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }

    }, [])

    const handleDateToChange = (value?: any) => {
        setDateTo(value)
    }

    const handleDateFromChange = (value?: any) => {
        setDateFrom(value)
    }

    const handleCurrencyChange = (value: string) => {
        setAccountCurrency(value)
    }

    const ConvertDate = (timestamp: any) => {
        let dateFormat = new Date(timestamp * 1000);

        return dateFormat.getDate() +
            "/" + (dateFormat.getMonth() + 1) +
            "/" + dateFormat.getFullYear()
    }

    return (
        <div className={s.wrapper}>
            <div className={s.statement_filters_wrapper}>
                <div className={s.filter_block}>
                    <div className={s.filter_title}>
                        Account Number
                    </div>
                    <MySelectAccounts item={accountsForSelect}
                                      disabled={accountCurrency !== 'ALL'}
                                      onAccountSelectHandler={(e: any) => setAccountNumber(e.target.value)}
                                      width={isMobile ? 343 : 322} height={isMobile ? 48 : 58}/>
                </div>
                <div className={s.filter_block}>
                    <div className={s.filter_title}>
                        From Date
                    </div>
                    <MaterialUIPickers value={dateFrom} handleDateChange={handleDateFromChange}/>
                </div>
                <div className={s.filter_block}>
                    <div className={s.filter_title}>
                        To Date
                    </div>
                    <MaterialUIPickers value={dateTo} handleDateChange={handleDateToChange}/>
                </div>
                <div className={s.filter_block}>
                    <div className={s.filter_title}>
                        Currency
                    </div>
                    <SelectSmall disabled={accountNumber !== "ALL"} item={currencyArr} width={isMobile ? 343 : 128} height={isMobile ? 48 : 58} setSelectedItem={handleCurrencyChange}/>
                </div>
                <div className={s.button_block}>
                    <CustomBtnPink title={"To Apply"} onClick={() => {filterByClick()}}/>
                </div>

            </div>
            <div className={s.statement_table}>
                <div className={s.table_header}>
                    <div className={s.header_item_short_date}>Date</div>
                    <div className={s.header_item_short}>Transfer Type</div>
                    <div className={s.header_item_long}>Sender</div>
                    <div className={s.header_item_long}>Beneficiary</div>
                    <div className={s.header_item_long}>Payment</div>
                    <div className={s.header_item_long}>Transactions number</div>
                    <div className={s.header_item_short_status}>
                        Status
                    </div>
                    <div className={s.header_item_short}>Amount</div>
                    <div className={s.header_item_short}>Balance</div>
                </div>
                <div className={s.row_wrapper}>
                    {
                        slicedTransactionsArr[currentPage - 1]?.map((transaction: any) => <div className={s.table_row}
                                                                              key={transaction.transactionNumber}>

                            <div className={s.header_item_short}>{ConvertDate(transaction.created)}</div>
                            <div className={s.header_item_short}>{transaction.transactionType}</div>
                            <div className={s.header_item_long}>{transaction.fromAccount}</div>

                            {
                                transaction.transactionType === "International Transaction"
                                    ?
                                    <Link to={`${(INTERNATIONAL_TRANSACTION_INFO)}?id=${transaction.transactionNumber}`}
                                          className={s.header_item_long_link}>
                                        {transaction.ibanCode}
                                    </Link>
                                    : <div className={s.header_item_long}>{transaction.toAccount}</div>
                            }

                            <div className={s.header_item_long}>{transaction.reference}</div>
                            <div className={s.header_item_long}>{transaction.transactionNumber}</div>
                            <div
                                className={s.header_item_short_status}>{getImgForTransactionStatus(transaction.transactionStatus)}</div>
                            <div className={s.header_item_short}>
                                {transactionDirectionIsOut(transaction.direction)
                                    ?
                                    <span className={s.out_style}> - {transaction.amount} {transaction.currency}</span>
                                    : <span className={s.in_style}> + {transaction.amount} {transaction.currency}</span>
                                }
                            </div>
                            <div className={s.header_item_short}>{transaction.balance} {transaction.currency}</div>
                        </div>)
                    }
                </div>
                <div className={s.table_footer}>
                    <div className={s.accounts_counter}>
                    </div>
                    <div>
                        <Paginator
                            totalItemsCount={filteredTransactions.length}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            itemsPerPage={itemsPerPage}
                        />
                    </div>
                </div>
                <div className={s.paginator_mobile}>
                    <Paginator
                        totalItemsCount={filteredTransactions.length}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        itemsPerPage={itemsPerPage}
                    />
                </div>
            </div>
        </div>
    );
};

export default Statement;
