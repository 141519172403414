import React, {Dispatch, SetStateAction} from 'react';
import { Pagination } from 'antd';
import './index.css'
import s from './Paginator.module.css'

type PropsType = {
    totalItemsCount: number
    setCurrentPage: Dispatch<SetStateAction<number>>
    currentPage: number
    itemsPerPage: number
}

const Paginator = (props: PropsType) => {

    const handlePageChange = (page: number) => {
        props.setCurrentPage(page)
    }

    return (
        <div className='paginator_wrapper'>
            <Pagination defaultCurrent={1}
                        nextIcon={<div className={s.change_page_icon_next}/>}
                        prevIcon={<div className={s.change_page_icon_prev}/>}
                        showTotal={(total = props.totalItemsCount, range) => `${range[0]}-${range[1]} of ${total}`}
                        current={props.currentPage}
                        total={props.totalItemsCount}
                        defaultPageSize={props.itemsPerPage}
                        showSizeChanger={false}
                        onChange={(page) => handlePageChange(page)}
            />
        </div>
    );
};

export default Paginator;
