import {
    CHANGE_PASSWORD,
    EXCHANGE,
    INTERNATIONAL_TRANSACTION_INFO,
    INTRA_TRANSFERS,
    STATEMENT,
    TRANSFERS
} from "./Constants";
import Statement from "../PersonalAccount/Statement/Statement";
import Transfers from "../PersonalAccount/Transfers/Transfers";
import IntraTransfers from "../PersonalAccount/IntraTransfers/IntraTransfers";
import Exchange from "../PersonalAccount/Exchange/Exchange";
import InternationalTransactionInfo
    from "../PersonalAccount/Statement/internationalTransactionInfo/InternationalTransactionInfo";
import {ChangePassword} from "../PersonalAccount/ChangePassword/ChangePassword";

export const privateRoutes = [
    {
        path: STATEMENT,
        title: "Statement",
        component: <Statement/>
    },
    {
        path: TRANSFERS,
        title: "Transfers",
        component: <Transfers/>
    },
    {
        path: INTRA_TRANSFERS,
        title: "Intra transfers",
        component: <IntraTransfers/>
    },
    {
        path: EXCHANGE,
        title: "Exchange",
        component: <Exchange/>
    },
    {
        path: INTERNATIONAL_TRANSACTION_INFO,
        title: "International Transaction Info",
        component: <InternationalTransactionInfo/>
    },
    {
        path: CHANGE_PASSWORD,
        title: "Change password",
        component: <ChangePassword/>
    }
]
