import {
    ABOUT_US,
    CARDS,
    COMMERCIAL_BANKING, CONTACT,
    DIGITAL_ASSETS_BANKING, FAQ,
    PRIVATE_BANKING,
    TRADE_FINANCE
} from "./Constants";
import AboutUs from "../publicPages/aboutUs/AboutUs";
import PrivateBanking from "../publicPages/privateBanking/PrivateBanking";
import CommercialBanking from "../publicPages/commercialBanking/CommercialBanking";
import Cards from "../publicPages/cards/Cards";
import DigitalAssetsBanking from "../publicPages/digitalAssetsBanking/DigitalAssetsBanking";
import Faq from "../publicPages/faq/Faq";
import TradeFinance from "../publicPages/tradeFinance/TradeFinance";
import Contact from "../publicPages/contact/Contact";

export const publicRoutes = [
    {
        path: ABOUT_US,
        title: "About us",
        component: <AboutUs/>
    },
    {
        path: PRIVATE_BANKING,
        title: "Private banking",
        component: <PrivateBanking/>
    },
    {
        path: COMMERCIAL_BANKING,
        title: "Commercial banking",
        component: <CommercialBanking/>
    },
    {
        path: CARDS,
        title: "Cards",
        component: <Cards/>
    },
    {
        path: DIGITAL_ASSETS_BANKING,
        title: "Digital assets banking",
        component: <DigitalAssetsBanking/>
    },
    {
        path: TRADE_FINANCE,
        title: "Trade finance",
        component: <TradeFinance/>
    },
    {
        path: FAQ,
        title: "FAQ",
        component: <Faq/>
    },
    {
        path: CONTACT,
        title: "Contact",
        component: <Contact/>
    },
]

