import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {useFormik} from "formik";
import s from "../businessForm/BusinessForm.module.css";
import CustomInput from "../../../components/ui/customInput/CustomInput";
import CustomBtnTransparent from "../../../components/ui/customBtnTransparent/CustomBtnTransparent";
import CustomBtnPink from "../../../components/ui/customBtnPink/CustomBtnPink";
import CustomSelectWithMui from "../../../components/ui/customSelectWithMui/CustomSelectWithMui";
import CustomCheckbox from "../../../components/ui/customCheckbox/CustomCheckbox";
import {sendFormDataThunk} from "../../../store/reducers/ActionCreators";
import {useAppDispatch, useAppSelector} from "../../../hooks/redux";
import {PrepareDataForServer} from "../../../helpers/PrepareDataForServer";
import MaterialUIPickers from "../../../components/ui/MyDatePicker/MyDatePicker";
import classnames from "classnames";
import * as yup from "yup";

type PropsType = {
    setCurrent: Dispatch<SetStateAction<number>>
    current: number
}

const PersonalForm = (props: PropsType) => {

    const isMessageSentSuccess: boolean | null = useAppSelector(state => state.userReducer.isMessageSentSuccess)

    useEffect(() => {
        if (isMessageSentSuccess) {
            props.setCurrent(2)
        }
    }, [isMessageSentSuccess])
    const [dateOfBirth, setDateOfBirth] = useState(null)
    const [idExpirationDate, setIdExpirationDate] = useState(new Date())
    const [isMobile, setIsMobile] = useState(false)

    useEffect(() => {

        if (window.innerWidth < 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }

    }, [])

    const idDocuments = [
        "Passport",
        "Driver’s license",
        "ID card",
    ]

    const tariffPlans = [
        "Standards",
        "Merchant",
        "Deposit",
    ]

    const [chosenDocument, setChosenDocument] = useState<any>()
    const [convertedChosenDocument, setConvertedChosenDocument] = useState<any>()
    const dispatch = useAppDispatch()

    const toBase64 = (file:any) => new Promise((resolve, reject) => {
        if (!file) {
            return
        }
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    useEffect(() => {
        toBase64(chosenDocument).then((r) => {
            setConvertedChosenDocument(r)
        })
    }, [chosenDocument])

    const personalFormik = useFormik({
        initialValues: {
            name: '',
            country: '',
            surname: '',
            countryOfResidence: '',
            dateOfBirth: null as any,
            countryCitizenship: '',
            address: '',
            phone: '',
            city: '',
            mobilePhone: '',
            stateProvinceRegion: '',
            skype: '',
            zipCode: '',

            typeOfIdDocument: '',
            idExpirationDate: null as any,
            idNumber: '',
            chosenDocument: null as any,

            preferredLoginName: '',
            emailAddress: '',
            onlineBankingMobilePhone: '',

            accountCurrencyUsd: false,
            accountCurrencyEur: false,
            accountCurrencyGpb: false,
            chosenTariffPlan: '',
        },
        onSubmit: async values => {
            values.dateOfBirth = dateOfBirth
            values.idExpirationDate = idExpirationDate

            const data = {
                subject: "Personal opening request",
                data: PrepareDataForServer(values, "personal" ),
                files: [
                    convertedChosenDocument
                ]
            }

            await dispatch(sendFormDataThunk(data))
        },
    });

    return (
        <form onSubmit={personalFormik.handleSubmit}>
            <div className={s.wrapper}>
                <div className={s.content}>

                    <div className={s.title}>
                        Personal Information
                    </div>
                    <div className={s.subtitle}>
                        Please, provide us with your personal information and contact details.
                    </div>
                    <div className={s.inputs_row}>
                        <div className={s.input_block}>
                            <CustomInput
                                id="name"
                                name="name"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.name}
                                placeholder={"Name *"}
                                required
                            />
                        </div>
                        <div className={s.input_block}>
                            <CustomInput
                                id="country"
                                name="country"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.country}
                                placeholder={"Country *"}
                                required
                            />
                        </div>
                    </div>
                    <div className={s.inputs_row}>
                        <div className={s.input_block}>
                            <CustomInput
                                id="surname"
                                name="surname"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.surname}
                                placeholder={"Surname *"}
                                required
                            />
                        </div>
                        <div className={s.input_block}>
                            <CustomInput
                                id="countryOfResidence"
                                name="countryOfResidence"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.countryOfResidence}
                                placeholder={"Country Of Residence *"}
                                required
                            />
                        </div>
                    </div>
                    <div className={s.inputs_row}>
                        <div className={s.input_block}>
                            <MaterialUIPickers value={dateOfBirth}
                                               handleDateChange={setDateOfBirth}
                                               placeholder={"Date of Birth *"}
                                               openingForm
                            />
                        </div>
                        <div className={s.input_block}>
                            <CustomInput
                                id="countryCitizenship"
                                name="countryCitizenship"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.countryCitizenship}
                                placeholder={"Country Citizenship *"}
                                required
                            />
                        </div>
                    </div>
                    <div className={s.inputs_row}>
                        <div className={s.input_block}>
                            <div className={s.describe}>Please use the physical address (no P.O. box, registered agent, board of directors or trustee addresses)</div>
                            <CustomInput
                                id="address"
                                name="address"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.address}
                                placeholder={"Address *"}
                                required
                            />
                        </div>
                        <div className={s.input_block}>
                            <div className={s.describe}>Please, submit your phone number in international format</div>
                            <CustomInput
                                id="phone"
                                name="phone"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.phone}
                                placeholder={"Phone *"}
                                required
                            />
                        </div>
                    </div>
                    <div className={s.inputs_row}>
                        <div className={s.input_block}>
                            <CustomInput
                                id="city"
                                name="city"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.city}
                                placeholder={"City *"}
                                required
                            />
                        </div>
                        <div className={s.input_block}>
                            <CustomInput
                                id="mobilePhone"
                                name="mobilePhone"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.mobilePhone}
                                placeholder={"Mobile Phone *"}
                                required
                            />
                        </div>
                    </div>
                    <div className={s.inputs_row}>
                        <div className={s.input_block}>
                            <CustomInput
                                id="stateProvinceRegion"
                                name="stateProvinceRegion"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.stateProvinceRegion}
                                placeholder={"State/Province/Region *"}
                                required
                            />
                        </div>
                        <div className={s.input_block}>
                            <CustomInput
                                id="skype"
                                name="skype"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.skype}
                                placeholder={"Skype *"}
                                required
                            />
                        </div>
                    </div>
                    <div className={s.inputs_row}>
                        <div className={s.input_block}>
                            <CustomInput
                                id="zipCode"
                                name="zipCode"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.zipCode}
                                placeholder={"Zip Code *"}
                                required
                            />
                        </div>
                        <div className={s.input_block}>

                        </div>
                    </div>

                    <div className={s.title}>
                        Identification Document Details
                    </div>
                    <div className={s.subtitle}>
                        Please, select you Identification Document type and upload a copy of the document using the
                        ‘Choose File’ option below.
                    </div>
                    <div className={s.inputs_row}>
                        <div className={classnames(s.input_block, s.margin_mobile)}>
                            <CustomSelectWithMui
                                         selectedItem={personalFormik.values.typeOfIdDocument}
                                         handleChange={personalFormik.handleChange}
                                         height={isMobile ? 40 : 58}
                                         width={400}
                                         item={idDocuments}
                                         id="typeOfIdDocument"
                                         name="typeOfIdDocument"
                                         placeholder={"Choose a value *"}
                                         required
                            />
                        </div>
                        <div className={s.input_block}>
                            <MaterialUIPickers value={idExpirationDate}
                                               handleDateChange={setIdExpirationDate}
                                               placeholder={"ID Expiration date *"}
                                               openingForm

                            />
                        </div>
                    </div>
                    <div className={s.inputs_row}>
                        <div className={s.input_block}>
                            <CustomInput
                                id="idNumber"
                                name="idNumber"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.idNumber}
                                placeholder={"ID Number *"}
                                required
                            />
                        </div>
                        <div className={s.input_block}>
                            <div className={s.describe}>
                                Please, upload a clear and legible copy of the front pages of
                                your passport, ID card or drive's license, including the photograph
                            </div>
                            <div className={s.input_file_block}>
                                <CustomInput
                                    id="chosenDocument"
                                    name="chosenDocument"
                                    type="input"
                                    onChange={personalFormik.handleChange}
                                    value={chosenDocument ? chosenDocument.name : ""}
                                    placeholder={chosenDocument ? chosenDocument.name : "Choose File *"}
                                    required
                                />
                                <input type="file"
                                       id="chosenDocumentHiddenPersonal"
                                       name="chosenDocument"
                                       accept="image/png, image/gif, image/jpeg"
                                       // value={personalFormik.values.chosenDocument ? personalFormik.values.chosenDocument : ""}
                                       className={s.hidden_input}
                                       onChange={(event) => {
                                           // @ts-ignore
                                           setChosenDocument(event.currentTarget.files[0]);
                                       }}
                                />
                                <div className={s.attach_button_block}>
                                    <CustomBtnPink title={"Attach"} isAttach onClick={(e) => {
                                        e.preventDefault()
                                        // @ts-ignore
                                        document.getElementById('chosenDocumentHiddenPersonal').click()
                                    }}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={s.title}>
                        Online Banking
                    </div>
                    <div className={s.subtitle}>
                        This Login Name will be used to enter your Online Banking system once account will be activated.
                    </div>
                    <div className={s.inputs_row}>
                        <div className={s.input_block}>
                            <CustomInput
                                id="preferredLoginName"
                                name="preferredLoginName"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.preferredLoginName}
                                placeholder={"Preferred Login Name *"}
                                required
                            />
                        </div>
                        <div className={s.input_block}>
                            <CustomInput
                                id="emailAddress"
                                name="emailAddress"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.emailAddress}
                                placeholder={"Email Address *"}
                                required
                            />
                        </div>
                    </div>
                    <div className={s.inputs_row}>
                        <div className={s.input_block}>
                            <CustomInput
                                id="onlineBankingMobilePhone"
                                name="onlineBankingMobilePhone"
                                type="text"
                                onChange={personalFormik.handleChange}
                                value={personalFormik.values.onlineBankingMobilePhone}
                                placeholder={"Mobile Phone *"}
                                required
                            />
                        </div>
                        <div className={s.input_block}>

                        </div>
                    </div>

                    <div className={s.title}>
                        Account Currency
                    </div>
                    <div className={s.subtitle}>
                        Please, choose one or more account currency types.
                    </div>
                    <div className={s.inputs_row_currency}>
                        <div className={ classnames(s.input_block, s.margin_mobile)}>
                            <div className={s.flex}>
                                <label className={s.flex_label}>
                                    <CustomCheckbox
                                        handleChange={personalFormik.handleChange}
                                        isChecked={personalFormik.values.accountCurrencyUsd}
                                        id="accountCurrencyUsd"
                                        name="accountCurrencyUsd"
                                    />
                                    <span className={s.currency}>USD</span>
                                </label>
                                <label className={s.flex_label}>
                                    <CustomCheckbox
                                        handleChange={personalFormik.handleChange}
                                        isChecked={personalFormik.values.accountCurrencyEur}
                                        id="accountCurrencyEur"
                                        name="accountCurrencyEur"
                                    />
                                    <span className={s.currency}>EUR</span>
                                </label>
                                <label className={s.flex_label}>
                                    <CustomCheckbox
                                        handleChange={personalFormik.handleChange}
                                        isChecked={personalFormik.values.accountCurrencyGpb}
                                        id="accountCurrencyGpb"
                                        name="accountCurrencyGpb"
                                    />
                                    <span className={s.currency}>GBP</span>
                                </label>
                            </div>
                        </div>
                        <div className={s.input_block}>
                            <CustomSelectWithMui
                                selectedItem={personalFormik.values.chosenTariffPlan}
                                handleChange={personalFormik.handleChange}
                                height={isMobile ? 40 : 58}
                                width={400}
                                item={tariffPlans}
                                id="chosenTariffPlan"
                                name="chosenTariffPlan"
                                placeholder={"Choose a value *"}/>
                        </div>
                    </div>

                    <div className={s.rules}>
                        By clicking Send you agree that all submitted information is accurate, up to date and that we
                        can contact you for service reasons by secure e-message (if you are registered for Online Banking),
                        email or text message by using the contact details you have provided during this application or
                        any you provide in the future.
                    </div>
                </div>
            </div>

            <div className={s.buttons_wrapper}>
                <div className={s.buttons_block}>
                    <div className={s.button_back}>
                        <CustomBtnTransparent title={"Back"} onClick={() => props.setCurrent(0)}/>
                    </div>
                    <div className={s.button_next}>
                        <CustomBtnPink title={"Send"} type={"submit"}/>
                    </div>
                </div>
            </div>

        </form>
    );
};

export default PersonalForm;
