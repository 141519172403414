import React, {useEffect, useState} from 'react';
import s from "./Exchange.module.css";
import {useAppDispatch, useAppSelector} from "../../hooks/redux";
import {sendIntraTransfer} from "../../store/reducers/ActionCreators";
import MySelectAccounts from "../../components/ui/MySelectAccounts/MySelectAccounts";
import {useAccountsForSelect} from "../../hooks/useAccountsForSelect";
import {useFormik} from "formik";
import CustomBtnPink from "../../components/ui/customBtnPink/CustomBtnPink";
import CustomCheckbox from "../../components/ui/customCheckbox/CustomCheckbox";
import * as yup from 'yup';
import classnames from "classnames";

const validationSchema = yup.object({
    amount: yup.number().required('Required field'),
})

const Exchange = () => {

    const dispatch = useAppDispatch()
    const userToken: string = useAppSelector(state => state.userReducer.user.userToken)
    const [selectedAccountCurrencyTo, setSelectedAccountCurrencyTo] = useState("All");
    const [selectedAccountCurrencyFrom, setSelectedAccountCurrencyFrom] = useState("All");
    const [checkedTerms, setCheckedTerms] = useState<boolean>(false);
    const [checkedError, setCheckedError] = useState<boolean>(false);
    const [isMobile, setIsMobile] = useState(false)
    const formikExchange = useFormik({
        initialValues: {
            fromAccount: "",
            toAccount: "",
            amount: undefined,
            reference: ""
        },
        validationSchema,
        onSubmit: values => {
            if (!checkedTerms) {
                setCheckedError(true)
                return
            }
            dispatch(sendIntraTransfer(userToken, values))
        },
    });

    useEffect(() => {
        accountsForSelect.map((account) => {
            if (formikExchange.values.fromAccount === "ALL") {
                setSelectedAccountCurrencyFrom("ALL")
                return
            }
            if (account.number === formikExchange.values.fromAccount) {
                setSelectedAccountCurrencyFrom(account.currency)
            }
        })
    },[formikExchange.values.fromAccount])

    useEffect(() => {
        accountsForSelect.map((account) => {
            if (formikExchange.values.toAccount === "ALL") {
                setSelectedAccountCurrencyTo("ALL")
                return
            }
            if (account.number === formikExchange.values.toAccount) {
                setSelectedAccountCurrencyTo(account.currency)
            }
        })
    },[formikExchange.values.toAccount])

    const accountsForSelect = useAccountsForSelect()

    const handleCheckboxChange = () => {
        setCheckedTerms(!checkedTerms)
        setCheckedError(false)
    }

    useEffect(() => {

        if (window.innerWidth < 768) {
            setIsMobile(true)
        } else {
            setIsMobile(false)
        }

    }, [])

    console.log(formikExchange.errors)

    return (
        <div className={s.wrapper}>
            <form onSubmit={formikExchange.handleSubmit}>
                <div className={s.inputs_wrapper}>
                    <div className={s.input_block}>
                        <div className={s.input_title}>
                            From Account
                        </div>
                        <MySelectAccounts item={accountsForSelect}
                                          width={isMobile ? 255 : 322}
                                          height={isMobile ? 40 : 58}
                                          id="fromAccount"
                                          name="fromAccount"
                                          onAccountSelectHandler={formikExchange.handleChange}
                                          selectedItem={formikExchange.values.fromAccount}
                        />
                    </div>

                    <div className={s.input_block}>
                        <div className={s.input_title}>
                            Currency
                        </div>
                        <div className={s.current_currency}>
                            {selectedAccountCurrencyFrom}
                        </div>
                    </div>
                </div>
                <div className={s.inputs_wrapper}>
                    <div className={s.input_block}>
                        <div className={s.input_title}>
                            To Account
                        </div>
                        <MySelectAccounts item={accountsForSelect}
                                          width={isMobile ? 255 : 322}
                                          height={isMobile ? 40 : 58}
                                          id="toAccount"
                                          name="toAccount"
                                          onAccountSelectHandler={formikExchange.handleChange}
                                          selectedItem={formikExchange.values.toAccount}
                        />
                    </div>

                    <div className={s.input_block}>
                        <div className={s.input_title}>
                            Currency
                        </div>
                        <div className={s.current_currency}>
                            {selectedAccountCurrencyTo}
                        </div>
                    </div>
                </div>
                <div className={s.inputs_wrapper}>
                    <div className={classnames(s.input_block, s.amount_input_block)}>
                        <div className={s.input_title}>
                            Amount
                        </div>
                        <input
                            id="amount"
                            name="amount"
                            onChange={formikExchange.handleChange}
                            value={formikExchange.values.amount}
                            type="text"
                            className={classnames( formikExchange.errors.amount ? s.error_input : "", s.my_input)}
                        />
                        {formikExchange.errors.amount && <div className={s.error}>numbers only</div>}
                    </div>
                </div>
                <div className={s.terms_block}>
                    <CustomCheckbox handleChange={handleCheckboxChange}
                                    isChecked={checkedTerms}
                    />
                    <span className={checkedError ? s.checked_error : ''}>I agree with GBH Coriolis</span>
                    <span className={s.pink}>
                        Terms and Conditions
                    </span>
                </div>
                <div className={s.transfer_button_wrapper}>
                    <div className={s.transfer_button_block}>
                        <CustomBtnPink title={"Exchange"} type={"submit"}/>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default Exchange;
