type PropsType ={
    name: string,
    country: string,
    surname: string,
    countryOfResidence: string,
    dateOfBirth: number,
    countryCitizenship: string,
    address: string,
    phone: string,
    city: string,
    mobilePhone: string,
    stateProvinceRegion: string,
    skype: string,
    zipCode: string,
    typeOfIdDocument: string,
    idExpirationDate: any,
    idNumber: string,
    preferredLoginName: string,
    emailAddress: string,
    onlineBankingMobilePhone?: string,
    chosenTariffPlan: string,
    beneficiaryCountry?: string,
    beneficiaryAddress?: string,
    beneficiaryPhone?: string,
    beneficiaryZipCode?: string,
    beneficiaryCity?: string,
    anticipatedTurnover?: string,
    registrationNumber?: string,
    companyName?: string,
    accountCurrencyUsd: boolean,
    accountCurrencyEur: boolean,
    accountCurrencyGpb: boolean,
}
export const PrepareDataForServer = (values: PropsType, formType: string) => {
    const currencyArr = []

    if (values.accountCurrencyUsd) {
        currencyArr.push("USD")
    }

    if (values.accountCurrencyEur) {
        currencyArr.push("EUR")
    }

    if (values.accountCurrencyGpb) {
        currencyArr.push("GPB")
    }

    if (formType === "personal") {

        return {
            name: values.name,
            country: values.country,
            surname: values.surname,
            countryOfResidence: values.countryOfResidence,
            dateOfBirth: values.dateOfBirth,
            countryCitizenship: values.countryCitizenship,
            address: values.address,
            phone: values.phone,
            city: values.city,
            mobilePhone: values.mobilePhone,
            stateProvinceRegion: values.stateProvinceRegion,
            skype: values.skype,
            zipCode: values.zipCode,

            typeOfIdDocument: values.typeOfIdDocument,
            idExpirationDate: values.idExpirationDate,
            idNumber: values.idNumber,

            preferredLoginName: values.preferredLoginName,
            emailAddress: values.emailAddress,
            onlineBankingMobilePhone: values.onlineBankingMobilePhone,

            accountCurrency: currencyArr.join(', '),
            chosenTariffPlan: values.chosenTariffPlan,
        }
    } else {
        return {
            companyName: values.companyName,
            zipCode: values.zipCode,
            registrationNumber: values.registrationNumber,
            city: values.city,
            address: values.address,
            country: values.country,
            phone: values.phone,
            anticipatedTurnover: values.anticipatedTurnover,

            name: values.name,
            beneficiaryCountry: values.beneficiaryCountry,
            surname: values.surname,
            countryOfResidence: values.countryOfResidence,
            dateOfBirth: values.dateOfBirth,
            countryCitizenship: values.countryCitizenship,
            beneficiaryAddress: values.beneficiaryAddress,
            beneficiaryPhone: values.beneficiaryPhone,
            beneficiaryZipCode: values.beneficiaryZipCode,
            mobilePhone: values.mobilePhone,
            beneficiaryCity: values.beneficiaryCity,
            skype: values.skype,
            stateProvinceRegion: values.stateProvinceRegion,
            emailAddress: values.emailAddress,

            typeOfIdDocument: values.typeOfIdDocument,
            idExpirationDate: values.idExpirationDate,
            idNumber: values.idNumber,

            preferredLoginName: values.preferredLoginName,
            accountCurrency: currencyArr.join(', '),
            chosenTariffPlan: values.chosenTariffPlan,
        }
    }
}
